import React, {useEffect, useState} from 'react'
import ReactGA from 'react-ga4'
import {
  Card,
  CardBody,
  CardImg,
  CardTitle,
  Col,
  Container,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
  Table,
} from 'reactstrap'
import {useDispatch, useSelector} from 'react-redux'

import {Controller, useForm} from 'react-hook-form'
import {addTravellers, getTour, loginBookingUser, verifyUser} from '../../store/booking/actions'
import {useHistory, useParams} from 'react-router-dom'
import getSymbolFromCurrency from 'currency-symbol-map'
import moment from 'moment'

const TravellersDetails = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useForm();
  const booking = useSelector((state) => state?.booking);
  const cart = useSelector((state) => state?.booking?.cart);
  const tour = useSelector((state) => state?.booking?.tour);
  const common = useSelector((state) => state?.common);
  const image = tour?.tour?.image?.formats?.medium?.url;
  const [password, setPassword] = useState("");
  const [selectedGroup, setselectedGroup] = useState(null);
  const [redirect, setRedirect] = useState(false);
  const [cancel, setCancel] = useState(false);

  const [totalTravellers, setTotalTravellers] = useState([]);

  const getTotalTravellers = () => {
    let count = cart?.totalPax;
    let arr = [];
    for (let index = 0; index < count; index++) {
      arr.push(index + 1);
    }
    setTotalTravellers([...arr]);
  };

  useEffect(() => {
    getTotalTravellers();
    dispatch(getTour(id));
  }, []);

  useEffect(() => {
    getTotalTravellers();
  }, [cart]);

  function handleSelectGroup(selectedGroup) {
    setselectedGroup(selectedGroup);
  }
  React.useEffect(() => {
    if (id) {
      if (!cart) {
        history.push(`/booking/${id}`);
      }
    } else {
      history.push("/booking");
    }
  }, []);

  const saveData = async (listData) => {
    const arr = [];
    let userObj = null;
    totalTravellers.forEach((element, index) => {
      if (listData[`email-${element}`]) {
        userObj = {
          firstName: listData[`firstName-${element}`],
          lastName: listData[`lastName-${element}`],
          birthday: listData[`birthDate-${element}`],
          email: listData[`email-${element}`]
            ? listData[`email-${element}`]
            : "",
          gender: listData[`gender-${element}`],
        };
      }
      const obj = {
        firstName: listData[`firstName-${element}`],
        lastName: listData[`lastName-${element}`],
        dob: listData[`birthDate-${element}`],
        email: listData[`email-${element}`] ? listData[`email-${element}`] : "",
        gender: listData[`gender-${element}`],
        specialNeeds: listData[`text-${element}`],
      };
      arr.push(obj);
    });

    if (cart) {
      await dispatch(addTravellers({traveller:arr,user:userObj}));
      setRedirect(true);
    }
  };
  useEffect(() => {
    if (booking?.user?.user) {
      setValue(`firstName-1`, booking?.user?.user?.firstName);
      setValue(`lastName-1`, booking?.user?.user?.lastName);
      setValue(`gender-1`, booking?.user?.user?.gender);
      setValue(`birthDate-1`, booking?.user?.user?.birthday);
    }
  }, [booking?.user?.user]);
  const onSubmit = (data) => saveData(data);
  const setTravellersLocal = () => {
    if (totalTravellers.length > 0) {
      cart &&
        cart.travelers &&
        cart.travelers
          .sort((a, b) => (b.email ? 1 : -1))
          .forEach((element, index) => {
            if (index + 1 === 1) {
              setValue("email-1", element?.email);
            }
            setValue(`firstName-${index + 1}`, element?.firstName);
            setValue(`lastName-${index + 1}`, element?.lastName);
            setValue(`gender-${index + 1}`, element?.gender);
            setValue(`needs-${index + 1}`, element?.needs);
            setValue(`birthDate-${index + 1}`, element?.dob);
            if (element?.specialNeeds) {
              setValue(`needs-${index + 1}`, "yes");
              setValue(`text-${index + 1}`, element?.specialNeeds);
            } else {
              setValue(`needs-${index + 1}`, "no");
            }
          });
    }
  };

  useEffect(() => {
    setTravellersLocal();
  }, [cart, cart?.travelers, totalTravellers]);
  useEffect(() => {
    setTravellersLocal();
  }, []);
  useEffect(() => {
    if (redirect && !common?.loading && common?.redirect) {
      history.push(`/account-data/${id}`);
    }
  }, [redirect, common?.loading, common?.redirect]);

  const handleLogin = async () => {
    dispatch(
      loginBookingUser({
        identifier: watch(`email-1`),
        password: password,
      })
    );
  };

  const handleVerifyUser = async () => {
    dispatch(
        verifyUser({
          email: watch(`email-1`),
        })
    );
  };
  ReactGA.send({ hitType: "pageview", page: "/travelers-details" });

  return (
    <React.Fragment>
      <div className="page-content padding-top">
        <Container fluid>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col xl="8" md={8} sm={12} xs={12}>
                <div>
                  {totalTravellers.map((item) => {
                    return (
                      <Card>
                        <CardBody>
                        <Row>
                          <Col>
                            <h3>
                              {item} Viaggiatore
                            </h3>
                          </Col>
                        </Row>
                          <Row>
                            <Col xs={12}>
                              {item === 1 && (
                                <div className="mb-3">
                                  <Label htmlFor="formrow-email-Input">
                                    Email*
                                  </Label>
                                  <Controller
                                    name={`email-${item}`}
                                    className="form-control"
                                    control={control}
                                    rules={{ required: true }}
                                    
                                    render={({ field }) => (
                                      <Input
                                        {...field}
                                        type="email"
                                        disabled={booking?.user?.user?.id?true:false}
                                        className="form-control"
                                        id="formrow-email-Input"
                                        onBlur={handleVerifyUser}
                                        placeholder="Inserisci la tua email"
                                        name={`email-${item}`}
                                        invalid={
                                          errors[`email-${item}`] ? true : false
                                        }
                                      />
                                    )}
                                  />
                                  {errors[`email-${item}`] ? (
                                    <FormFeedback type="invalid">
                                      {"Campo richiesto"}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              )}
                            </Col>
                          </Row>
                          {booking?.verified?.id && item === 1 && !cancel && (
                            <>
                              <Row>
                                <Col>
                                  <div
                                    class="alert alert-success d-flex align-items-center"
                                    role="alert"
                                  >
                                    <Col xs={11}>
                                      <span>
                                        {" "}
                                        Inserisci la tua password
                                      </span>
                                    </Col>
                                    <Col
                                      xs={1}
                                      className="d-flex justify-content-end pointer"
                                    >
                                      <span onClick={(x) => setCancel(true)}>
                                        X
                                      </span>
                                    </Col>
                                  </div>
                                </Col>
                              </Row>
                              <Row className="d-flex align-items-center">
                                <Col xs={12} md={6} xl={6} lg={6}>
                                  <div className="mb-3">
                                    <Label htmlFor="formrow-password-Input">
                                      Password
                                    </Label>

                                    <Input
                                      type="password"
                                      className="form-control"
                                      placeholder="Password"
                                      value={password}
                                      onChange={(e) =>
                                        setPassword(e.target.value)
                                      }
                                    />
                                  </div>
                                </Col>
                                <Col
                                  className=""
                                  xs={12}
                                  md={6}
                                  xl={6}
                                  lg={6}
                                  className=" d-flex align-items-center justify-content-end"
                                >
                                  <span
                                    className="btn btn-primary  p-2 px-4 d-flex align-items-center justify-content-center"
                                    onClick={() => {
                                      handleLogin();
                                    }}
                                    style={{ minWidth: "90px" }}
                                  >
                                    {common?.loading &&
                                    common?.loaderName === "LOGIN_USER" ? (
                                      <Spinner
                                        animation="border"
                                        variant="secondary"
                                        // size="sm"
                                        style={{
                                          height: "18px",
                                          width: "18px",
                                        }}
                                      />
                                    ) : (
                                      "Accedi"
                                    )}
                                  </span>
                                </Col>
                              </Row>
                            </>
                          )}
                          <Row>
                            <Col md={6} xs={12}>
                              <div className="mb-3">
                                <Label htmlFor="formrow-firstname-Input">
                                  Nome*
                                </Label>
                                <Controller
                                  name={`firstName-${item}`}
                                  className="form-control"
                                  control={control}
                                  rules={{ required: true }}
                                  render={({ field }) => (
                                    <Input
                                      {...field}
                                      name={`firstName-${item}`}
                                      type="text"
                                      className="form-control"
                                      id="formrow-firstname-Input"
                                      placeholder="Inserisci nome"
                                      invalid={
                                        errors[`firstName-${item}`]
                                          ? true
                                          : false
                                      }
                                    />
                                  )}
                                />
                                {errors[`firstName-${item}`] ? (
                                  <FormFeedback type="invalid">
                                    {"Campo richiesto"}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col md={6} xs={12}>
                              <div className="mb-3">
                                <Label htmlFor="formrow-lastname-Input">
                                  Cognome*
                                </Label>
                                <Controller
                                  name={`lastName-${item}`}
                                  className="form-control"
                                  control={control}
                                  rules={{ required: true }}
                                  render={({ field }) => (
                                    <Input
                                      {...field}
                                      name={`lastName-${item}`}
                                      type="text"
                                      className="form-control"
                                      id="formrow-lastname-Input"
                                      placeholder="Inserisci cognome"
                                      invalid={
                                        errors[`lastName-${item}`]
                                          ? true
                                          : false
                                      }
                                    />
                                  )}
                                />
                                {errors[`lastName-${item}`] ? (
                                  <FormFeedback type="invalid">
                                    {"Campo richiesto"}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6} xs={12}>
                              <div className="mb-3">
                                <label
                                  htmlFor="example-date-input"
                                  className=" col-form-label pt-0"
                                >
                                  Compleanno*
                                </label>
                                <div className="">
                                  <Controller
                                    name={`birthDate-${item}`}
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                      <Input
                                        {...field}
                                        name={`birthDate-${item}`}
                                        className="form-control"
                                        type="date"
                                        max={
                                          new Date().toISOString().split("T")[0]
                                        }
                                        id="formrow-birthdate"
                                        invalid={
                                          errors[`birthDate-${item}`]
                                            ? true
                                            : false
                                        }
                                      />
                                    )}
                                  />

                                  {errors[`birthDate-${item}`] ? (
                                    <FormFeedback type="invalid">
                                      {"Campo richiesto"}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </div>
                            </Col>
                            <Col md={6} xs={12}>
                              <div className="mb-3">
                                <Label htmlFor="formrow-firstname-Input">
                                  Sesso*
                                </Label>
                                <Controller
                                  control={control}
                                  rules={{ required: true }}
                                  name={`gender-${item}`}
                                  render={({ field }) => (
                                    <>
                                      <FormGroup check row {...field}>
                                        <Row>
                                          <Col>
                                            <div className="form-check mb-2 mt-2 ">
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                id={`gender-${item}-female`}
                                                value="male"
                                                name={`gender-${item}`}
                                                checked={
                                                  watch(`gender-${item}`) ===
                                                  "male"
                                                }
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor={`gender-${item}-female`}
                                              >
                                                Maschio
                                              </label>
                                            </div>
                                          </Col>
                                          <Col>
                                            <div className="form-check mb-2 mt-2 ">
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                id={`gender-${item}-male`}
                                                value="female"
                                                checked={
                                                  watch(`gender-${item}`) ===
                                                  "female"
                                                }
                                                name={`gender-${item}`}
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor={`gender-${item}-male`}
                                              >
                                                Femmina
                                              </label>
                                            </div>
                                          </Col>
                                        </Row>
                                        {errors[`gender-${item}`] ? (
                                          <small
                                            style={{ color: "red" }}
                                            type="invalid"
                                          >
                                            {"Campo richiesto"}
                                          </small>
                                        ) : null}
                                      </FormGroup>
                                    </>
                                  )}
                                />
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    );
                  })}

                    <Card>
                      <CardBody>
                        <Col xs={12} className="mt-1">
                          <h3>Richieste speciali</h3>
                          <p>
                            Dicci se hai bisogno di qualcosa in particolare. Qui potresti scrivere le tue allergie, malattie che dovremmo sapere, richiete di cibo, problemi fisici, medicazioni o qualsiasi altra richiesta che hai.
                          </p>
                          <Row className="needs-card">
                            {totalTravellers.map((item) => {
                              return (
                                <>
                                  <Controller
                                    control={control}
                                    rules={{ required: true }}
                                    name={`needs-${item}`}
                                    render={({ field }) => (
                                      <>
                                        <FormGroup row {...field}>
                                          <Row>
                                            <Row>
                                              <Col xs={7}>
                                                <p className="mb-2 mt-2">
                                                  Viaggiatore {item}
                                                </p>
                                              </Col>
                                              <Col>
                                                <div className="form-check mb-2 mt-2">
                                                  <Input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name={`needs-${item}`}
                                                    value="yes"
                                                    checked={
                                                      watch(`needs-${item}`) ===
                                                      "yes"
                                                    }
                                                  />
                                                  <label className="form-check-label">
                                                    Si
                                                  </label>
                                                </div>
                                              </Col>
                                              <Col>
                                                <div className="form-check mb-2 mt-2">
                                                  <Input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name={`needs-${item}`}
                                                    value="no"
                                                    checked={
                                                      watch(`needs-${item}`) ===
                                                      "no"
                                                    }
                                                  />
                                                  <label className="form-check-label">
                                                    No
                                                  </label>
                                                </div>
                                              </Col>
                                              {errors[`needs-${item}`] ? (
                                                <small
                                                  style={{ color: "red" }}
                                                  type="invalid"
                                                >
                                                  {"Campo richiesto"}
                                                </small>
                                              ) : null}
                                            </Row>
                                          </Row>
                                        </FormGroup>
                                      </>
                                    )}
                                  />
                                  {watch(`needs-${item}`) === "yes" && (
                                    <Row>
                                      <Col>
                                        <Controller
                                          name={`text-${item}`}
                                          className="form-control"
                                          control={control}
                                          rules={{ required: true }}
                                          render={({ field }) => (
                                            <Input
                                              {...field}
                                              type="textarea"
                                              id="textarea"
                                              maxLength="225"
                                              rows="4"
                                              invalid={
                                                errors[`text-${item}`]
                                                  ? true
                                                  : false
                                              }
                                              placeholder=""
                                            />
                                          )}
                                        />
                                      </Col>
                                    </Row>
                                  )}
                                </>
                              );
                            })}
                          </Row>
                        </Col>
                      </CardBody>
                    </Card>
                    <Card>
                      <CardBody>
                        <Col xs={12}>
                          <h3>
                            Accettazione dei termini e condizioni e privacy policy.
                          </h3>
                          <p>
                            Procedendo con il prossio step dichiaro di aver letto la <a href={"https://www.iubenda.com/privacy-policy/43774671"} target={"_blank"}>privacy policy</a> e di accettare i <a href={"https://www.weshoot.it/terms"} target={"_blank"}> termini e condizioni generali</a> di utilizzo dei servizi del sito WeShoot.
                          </p>
                          <p>
                            Acconsento a ricevere notizie sui prossimi viaggi fotografici che WeShoot fará via email, telefono o sms.
                          </p>
                          <Controller
                              control={control}
                              rules={{ required: true }}
                              name="agree"
                              render={({ field }) => (
                                  <>
                                    <FormGroup check row {...field}>
                                      <Row>
                                        <Col>
                                          <div className="form-check mb-2 mt-2 ">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="agree"
                                                id="agree1"
                                                value="agree"
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor="agree1"
                                            >
                                              Acconsento
                                            </label>
                                          </div>
                                        </Col>
                                        <Col>
                                          <div className="form-check mb-2 mt-2 ">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="agree"
                                                id="agree2"
                                                value="dontagree"
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor="agree2"
                                            >
                                              Non acconsento
                                            </label>
                                          </div>
                                        </Col>
                                        {errors[`agree`] ? (
                                            <small
                                                style={{ color: "red" }}
                                                type="invalid"
                                            >
                                              {"Campo richiesto"}
                                            </small>
                                        ) : null}
                                      </Row>
                                    </FormGroup>
                                  </>
                              )}
                          />

                        </Col>
                      </CardBody>
                    </Card>
                </div>
              </Col>
              <Col xl="4" md={4}>
                <Card className="card-border">
                  <CardBody>
                    <CardImg src={image} style={ {borderRadius: "8px", marginBottom: "16px"}}/>
                    <CardTitle className="mb-3">Riepilogo</CardTitle>
                    {tour && <div style={{textAlign: "center"}}>Dal {moment(tour.start).locale("it").format("DD/MM/YYYY")} al {moment(tour.end).locale("it").format("DD/MM/YYYY")}</div>}
                    <div className="table-responsive">
                      <Table className="table mb-0">
                        <tbody>
                          <tr>
                            <td>{tour?.tour?.title}:</td>
                            {tour?.price && (
                              <td className="text-end summary-col">{`${getSymbolFromCurrency(
                                tour?.currency
                              )} ${tour?.price}`}</td>
                            )}
                          </tr>

                          <tr>
                            <th>Saldo</th>
                            {cart?.finalPrice && (
                              <th className="text-end summary-col">
                                {`${getSymbolFromCurrency(tour?.currency)} ${
                                  cart?.finalPrice
                                }`}
                              </th>
                            )}
                          </tr>
                          <tr>
                            <th>Totale da pagare oggi</th>

                            <th className="text-end summary-col">
                              €1
                            </th>

                          </tr>
                        </tbody>
                      </Table>
                    </div>
                    <button
                        className="btn btn-primary w-100"
                        type="submit"
                        style={{
                          minHeight: "90px",
                          background:
                              common?.loading &&
                              common?.loaderName === "UPDATE_CART" &&
                              "#000000d1",
                          borderColor:
                              common?.loading &&
                              common?.loaderName === "UPDATE_CART" &&
                              "#000000d1",
                        }}
                    >
                      {common?.loading &&
                      common?.loaderName === "UPDATE_CART" ? (
                          <Spinner animation="border" variant="secondary" />
                      ) : (
                          <>
                            <p>
                              Ho letto i termini e la privacy policy
                            </p>
                            <b> Conferma e continua</b>
                          </>
                      )}
                    </button>
                  </CardBody>
                </Card>
                <Row>
                  <div className="d-flex align-items-center justify-content-center flex-column">
                    <h5 className="pointer">Hai bisogno di aiuto? </h5>
                    <button
                        className="btn btn-default   w-100"
                        onClick={() => {
                          customerly.open();
                        }}
                    >
                      Contattaci{" "}
                    </button>
                  </div>
                </Row>

              </Col>
            </Row>
          </form>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default TravellersDetails;
