import {call, put, takeEvery, takeLatest} from 'redux-saga/effects'

// Login Redux States
import {GET_LOGGEDIN_USER, LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN} from './actionTypes'
import {apiError, loginSuccess, logoutUserSuccess} from './actions'

//Include Both Helper File with needed methods
import {getFirebaseBackend} from '../../../helpers/firebase_helper'
import {postSocialLogin} from '../../../helpers/fakebackend_helper'
import {currenUser, userLogin} from '../../../helpers/auth_helper'

const fireBaseBackend = getFirebaseBackend();

function* loginUser({ payload: { user, history } }) {
  try {
    let loginObj = {
      identifier: user.email,
      password: user.password,
    };
    const loginResponse = yield call(userLogin, loginObj);
    localStorage.setItem("authUser", JSON.stringify(loginResponse));
    localStorage.setItem("weshoot-user-id", loginResponse?.user?.id);
    localStorage.setItem("weshoot-jwt-token", loginResponse?.jwt);
    yield put(loginSuccess(loginResponse));
    localStorage.setItem("role",loginResponse?.user?.role?.type)
    if (loginResponse?.user?.role?.type === "user") {
      history.push("/users/bookings");
    } else {
      history.push("/admin/dashboard");
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser");
    localStorage.removeItem("role")
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.logout);
      yield put(logoutUserSuccess(response));
    }
    history.push("/login");
  } catch (error) {
    yield put(apiError(error));
  }
}

function* socialLogin({ payload: { data, history, type } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const fireBaseBackend = getFirebaseBackend();
      const response = yield call(fireBaseBackend.socialLoginUser, data, type);
      localStorage.setItem("authUser", JSON.stringify(response));
      yield put(loginSuccess(response));
    } else {
      const response = yield call(postSocialLogin, data);
      localStorage.setItem("authUser", JSON.stringify(response));
      yield put(loginSuccess(response));
    }
    history.push("/dashboard");
  } catch (error) {
    yield put(apiError(error));
  }
}

function* getCurrentUser() {
  try {
    if (localStorage.getItem("weshoot-jwt-token")) {
      const loginResponse = yield call(currenUser);

      yield put(loginSuccess({ user: loginResponse }));
    }
  } catch (error) {
    yield put(apiError(error));
  }
}
function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeLatest(SOCIAL_LOGIN, socialLogin);
  yield takeEvery(LOGOUT_USER, logoutUser);
  yield takeEvery(GET_LOGGEDIN_USER, getCurrentUser);
}

export default authSaga;
