import React, {useEffect, useState} from 'react'
import {Controller, useForm} from 'react-hook-form'
import ReactGA from 'react-ga4'

import {
  Card,
  CardBody,
  CardImg,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
  Table,
} from 'reactstrap'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory, useParams} from 'react-router-dom'
import {getMe, saveUser} from '../../store/booking/actions'
import getSymbolFromCurrency from 'currency-symbol-map'
import {phone} from 'phone'
import 'react-phone-input-2/lib/style.css'
import moment from 'moment'

const AccountData = () => {
  const history = useHistory();
  const { id } = useParams();
  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const [userData, setUserData] = useState(null);
  const [redirect, setRedirect] = useState(false);

  const booking = useSelector((state) => state?.booking);
  const cart = useSelector((state) => state?.booking?.cart);
  const tour = useSelector((state) => state?.booking?.tour);
  const common = useSelector((state) => state?.common);
  const image = tour?.tour?.image?.formats?.medium?.url;
  const setTravellersLocal = () => {
    try {
      if (booking?.user?.user) {
        setUserData({ ...booking?.user?.user });
      }
      // let arr =
      //   cart &&
      //   cart.travelers &&
      //   cart.travelers.sort((a, b) => (b.email ? 1 : -1));
      // setUserData(arr[0]);
    } catch (e) {}
  };
  React.useEffect(() => {
    if (id) {
      if (!cart) {
        history.push(`/booking/${id}`);
      }
    } else {
      history.push("/booking");
    }
    // if (localStorage.getItem("weshoot-token")) {
    dispatch(getMe());
    // }
  }, []);
  useEffect(() => {
    setTravellersLocal();
  }, [cart, cart?.travelers]);

  const setUserLocal = () => {
    setValue(`PaymenyType`, cart?.PaymenyType);
    if (cart && cart.user) {
      setValue(`phoneNumber`, cart.user?.phone);
      setValue(`placeOfBirth`, cart.user?.placeOfBirth);
      setValue(`fiscalCode`, cart.user?.fiscalCode);

      const add = cart?.user?.address;

      setValue(`address`, add?.address);
      setValue(`apartment`, add?.apartment);
      setValue(`city`, add?.city);
      setValue(`state`, add?.state);
      setValue(`zipCode`, add?.zipCode);
      setValue(`country`, add?.country);
      const em = cart?.user?.emergencyContact;
      setValue(`em_name`, em?.em_name);
      setValue(`em_phone`, em?.em_phone);
      setValue(`relationship`, em?.relationship);
    }
  };
  useEffect(() => {
    setUserLocal();
  }, [cart, cart?.user, cart?.PaymenyType]);
  useEffect(() => {
    setUserLocal();
  }, []);
  const submitData = async (data) => {
    let userObj = {
      email: userData?.email,
      firstName: userData?.firstName,
      lastName: userData?.lastName,
      gender: userData?.gender,
      birthday: userData?.dob,
      phone: data.phoneNumber,
      placeOfBirth: data.placeOfBirth,
      fiscalCode: data.fiscalCode,
      address: {
        address: data.address,
        apartment: data.apartment,
        city: data.city,
        country: data.country,
        zipCode: data.zipCode,
        state: data.state,
      },
      emergencyContact: {
        em_phone: data.em_phone,
        em_name: data.em_name,
        relationship: data.relationship,
      },
    };
    let cartData = {
      PaymenyType: data?.PaymenyType,
      balanceAmount:
        data?.PaymenyType === "full" ? 0 : ( cart?.totalPax * tour?.deposit).toFixed(2),
    };
    // price(pin):2299
    // deposit(pin):100
    // balance(pin):2199
    await dispatch(saveUser({ user: userObj, cartData: cartData }));
    setRedirect(true);
  };
  const onSubmit = (data) => submitData(data);
  useEffect(() => {
    if (redirect && !common?.loading && common?.redirect) {
      history.push(`/payment/${id}`);
    }
  }, [redirect, common?.loading, common?.redirect]);
  useEffect(() => {
    setUserFormData();
  }, []);
  useEffect(() => {
    setUserFormData();
  }, [booking]);
  const setUserFormData = () => {
    if (booking?.user?.user) {
      setValue(`phone`, booking?.user?.user?.phone);
      setValue(`placeOfBirth`, booking?.user?.user?.placeOfBirth);
      setValue(`fiscalCode`, booking?.user?.user?.fiscalCode);
      const add = booking?.user?.user?.address;
      setValue(`address`, add?.address);
      setValue(`apartment`, add?.apartment);
      setValue(`city`, add?.city);
      setValue(`state`, add?.state);
      setValue(`zipCode`, add?.zipCode);
      setValue(`country`, add?.country);
      const em = booking?.user?.user?.emergencyContact;
      setValue(`em_name`, em?.em_name);
      setValue(`em_phone`, em?.em_phone);
      setValue(`relationship`, em?.relationship);
    }
  };

  ReactGA.send({ hitType: "pageview", page: "/account-data" });

  return (
    <React.Fragment>
      <div className="page-content padding-top">
        <Container fluid>
          <Form className="" onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col lx="8" md={8}>
                <Card>
                  <CardBody>
                    <Row>
                      <Col xs={12} md={6} xl={6} lg={6}>
                        <Row className="">
                          <Col style={{ maxWidth: "55px" }} className="p-0">
                            <h5 className="font-size-14 text-truncate">
                              Nome:
                            </h5>
                          </Col>
                          <Col className="p-0">
                            <p className="text-muted mb-0">
                              {userData?.firstName}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={12} md={6} xl={6} lg={6}>
                        <Row className="">
                          <Col style={{ maxWidth: "85px" }} className="p-0">
                            <h5 className="font-size-14 text-truncate">
                              Cognome:
                            </h5>
                          </Col>
                          <Col className="p-0">
                            <p className="text-muted mb-0">
                              {userData?.lastName}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={12} md={6} xl={6} lg={6}>
                        <Row className="">
                          <Col style={{ maxWidth: "45px" }} className="p-0">
                            <h5 className="font-size-14 text-truncate">
                              Email:
                            </h5>
                          </Col>
                          <Col className="p-0">
                            <p className="text-muted mb-0">{userData?.email}</p>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={12} md={6} xl={6} lg={6}>
                        <Row className="">
                          <Col style={{ maxWidth: "105px" }} className="p-0">
                            <h5 className="font-size-14 text-truncate">
                              Compleanno:
                            </h5>
                          </Col>
                          <Col className="p-0">
                            <p className="text-muted mb-0">
                              {userData?.birthday}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={6} xl={6} lg={6}>
                        <Row className="">
                          <Col style={{ maxWidth: "60px" }} className="p-0">
                            <h5 className="font-size-14 text-truncate">
                              Sesso:
                            </h5>
                          </Col>
                          <Col className="p-0">
                            <p className="text-muted mb-0">
                              {userData?.gender}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      <hr className="mt-4" />
                    </Row>

                    <Row>
                      <Row className=" mb-3">
                        <Col className="p-0">
                          <h5>Generali</h5>
                        </Col>
                      </Row>
                      <Row className="mb-5 g-0 p-0 ">
                        <Row>
                          <Col md={4} xs={12} className="p-0 pt-3 pt-sm-0">
                            <Label htmlFor="formrow-phonenumber-Input">
                              Telefono*
                            </Label>

                            <Controller
                                name="phoneNumber"
                                control={control}
                                className="form-control"
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <Input
                                        {...field}
                                        type="text"
                                        placeholder="telefono"
                                        className="form-control"
                                        id="formrow-phonenumber-Input"
                                        invalid={!!errors.phoneNumber}
                                    />
                                )}
                            />
                            {errors.phoneNumber ? (
                                <FormFeedback type="invalid">
                                  {"Campo richiesto"}
                                </FormFeedback>
                            ) : null}

                          </Col>
                          <Col
                            md={4}
                            xs={12}
                            className="p-0 ps-md-3 pt-3 pt-md-0"
                          >
                            <Label htmlFor="formrow-placeofbirth-Input">
                              Luogo di nascita*
                            </Label>
                            <Controller
                              name="placeOfBirth"
                              control={control}
                              className="form-control"
                              rules={{ required: true }}
                              render={({ field }) => (
                                <Input
                                  {...field}
                                  type="text"
                                  className="form-control"
                                  id="formrow-placeofbirth-Input"
                                  placeholder="Roma"
                                  invalid={errors.placeOfBirth ? true : false}
                                />
                              )}
                            />
                            {errors.placeOfBirth ? (
                              <FormFeedback type="invalid">
                                {"Campo richiesto"}
                              </FormFeedback>
                            ) : null}
                          </Col>
                          <Col
                            md={4}
                            xs={12}
                            className="p-0 ps-md-3 pt-3 pt-md-0"
                          >
                            <Label htmlFor="formrow-fiscalcode-Input">
                              Codice fiscale*
                            </Label>
                            <Controller
                              name="fiscalCode"
                              control={control}
                              className="form-control"
                              rules={{ required: true }}
                              render={({ field }) => (
                                <Input
                                  {...field}
                                  type="text"
                                  className="form-control"
                                  id="formrow-fiscalCode-Input"
                                  placeholder="ANGELN28H4203"
                                  invalid={errors.fiscalCode ? true : false}
                                />
                              )}
                            />
                            {errors.fiscalCode ? (
                              <FormFeedback type="invalid">
                                {"Campo richiesto"}
                              </FormFeedback>
                            ) : null}
                          </Col>
                        </Row>
                      </Row>
                      <hr className="mb-5" />
                    </Row>

                    <Row>
                      <Row className=" mb-3">
                        <Col className="p-0">
                          <h5>Indirizzo di residenza</h5>
                        </Col>
                      </Row>

                      <Row className=" mb-3">
                        <Col className="p-0">
                          <Label htmlFor="formrow-address-Input">
                            Indirizzo*
                          </Label>
                          <Controller
                            name="address"
                            control={control}
                            className="form-control"
                            rules={{ required: true }}
                            render={({ field }) => (
                              <Input
                                {...field}
                                type="text"
                                className="form-control"
                                id="formrow-address-Input"
                                placeholder="123 honey park"
                                invalid={errors.address ? true : false}
                              />
                            )}
                          />
                          {errors.address ? (
                            <FormFeedback type="invalid">
                              {"Campo richiesto"}
                            </FormFeedback>
                          ) : null}
                        </Col>
                      </Row>

                      <Row className=" mb-3">
                        <Col className="p-0 " xs={12} md={9} xl={9} lg={9}>
                          <Label htmlFor="formrow-city-Input">Cittá*</Label>
                          <Controller
                            name="city"
                            control={control}
                            className="form-control"
                            rules={{ required: true }}
                            render={({ field }) => (
                              <Input
                                {...field}
                                type="text"
                                className="form-control"
                                id="formrow-city-Input"
                                placeholder="Dublin"
                                invalid={errors.city ? true : false}
                              />
                            )}
                          />
                          {errors.city ? (
                            <FormFeedback type="invalid">
                              {"Campo richiesto"}
                            </FormFeedback>
                          ) : null}
                        </Col>
                        <Col
                          className="p-0 ps-md-3 pt-3 pt-md-0"
                          xs={12}
                          md={3}
                          xl={3}
                          lg={3}
                        >
                          <Label htmlFor="formrow-state-Input">
                            Provincia*
                          </Label>
                          <Controller
                            name="state"
                            control={control}
                            className="form-control"
                            rules={{ required: true }}
                            render={({ field }) => (
                              <Input
                                {...field}
                                type="text"
                                className="form-control"
                                id="formrow-state-Input"
                                placeholder=""
                                invalid={errors.state ? true : false}
                              />
                            )}
                          />
                          {errors.state ? (
                            <FormFeedback type="invalid">
                              {"Campo richiesto"}
                            </FormFeedback>
                          ) : null}
                        </Col>
                      </Row>
                      <Row className=" mb-3" style={{}}>
                        <Col className="p-0" xs={12} md={3} xl={3} lg={3}>
                          <Label htmlFor="formrow-zipcode-Input">
                            Codice postale*
                          </Label>
                          <Controller
                            name="zipCode"
                            control={control}
                            className="form-control"
                            rules={{ required: true }}
                            render={({ field }) => (
                              <Input
                                {...field}
                                type="text"
                                className="form-control"
                                id="formrow-zipCode-Input"
                                placeholder="Dublin"
                                invalid={errors.zipCode ? true : false}
                              />
                            )}
                          />
                          {errors.zipCode ? (
                            <FormFeedback type="invalid">
                              {"Campo richiesto"}
                            </FormFeedback>
                          ) : null}
                        </Col>
                        <Col
                          className="p-0 ps-md-3 pt-3 pt-md-0"
                          xs={12}
                          md={9}
                          xl={9}
                          lg={9}
                        >
                          <Label htmlFor="formrow-country-Input">Paese*</Label>
                          <Controller
                            name="country"
                            control={control}
                            className="form-control"
                            rules={{ required: true }}
                            render={({ field }) => (
                              <Input
                                {...field}
                                type="text"
                                className="form-control"
                                id="formrow-country-Input"
                                placeholder=""
                                invalid={errors.country ? true : false}
                              />
                            )}
                          />
                          {errors.country ? (
                            <FormFeedback type="invalid">
                              {"Campo richiesto"}
                            </FormFeedback>
                          ) : null}
                        </Col>
                      </Row>

                      <hr className="mt-5" />
                      <Row className="g-0 px-2">
                        <Row className=" mb-3 mt-4">
                          <Col className="p-0">
                            <h5>Contatto di emergenza</h5>
                          </Col>
                        </Row>

                        <Row className=" mb-3 px-1" style={{}}>
                          <Col
                            className="p-0 pt-3 pt-sm-0"
                            xs={12}
                            md={4}
                            xl={4}
                            lg={4}
                          >
                            <Label htmlFor="formrow-name-Input">Nome</Label>
                            <Controller
                              name="em_name"
                              control={control}
                              className="form-control"
                              rules={{ required: false }}
                              render={({ field }) => (
                                <Input
                                  {...field}
                                  type="text"
                                  className="form-control"
                                  id="formrow-em-name-Input"
                                  placeholder=""
                                />
                              )}
                            />
                          </Col>
                          <Col
                            className="p-0 ps-md-3 pt-3 pt-md-0"
                            xs={12}
                            md={4}
                            xl={4}
                            lg={4}
                          >
                            <Label htmlFor="formrow-name-Input">
                              Numero di telefono
                            </Label>
                            <Controller
                              name="em_phone"
                              control={control}
                              className="form-control"
                              rules={{ required: false }}
                              render={({ field }) => (
                                <Input
                                  {...field}
                                  type="text"
                                  className="form-control"
                                  id="formrow-em-phone-Input"
                                  placeholder=""
                                />
                              )}
                            />
                          </Col>
                          <Col
                            className="p-0 ps-md-3 pt-3 pt-md-0"
                            xs={12}
                            md={4}
                            xl={4}
                            lg={4}
                          >
                            <Label htmlFor="formrow-relationshiptype-Input">
                              Parentela
                            </Label>
                            <Controller
                              name="relationship"
                              control={control}
                              className="form-control"
                              render={({ field }) => (
                                <Input
                                  {...field}
                                  type="text"
                                  className="form-control"
                                  id="formrow-relationship-Input"
                                  placeholder=""
                                  // invalid={errors.relationship ? true : false}
                                />
                              )}
                            />
                          </Col>
                        </Row>
                      </Row>
                      <hr className="mt-5" />
                    </Row>

                    {/*<Row className="pb-4">*/}
                    {/*  <Row className=" mb-3 mt-4">*/}
                    {/*    <Col className="p-0">*/}
                    {/*      <h5>Come preferisci pagare?</h5>*/}
                    {/*    </Col>*/}
                    {/*  </Row>*/}
                    {/*  <Controller*/}
                    {/*    control={control}*/}
                    {/*    rules={{ required: true }}*/}
                    {/*    name="PaymenyType"*/}
                    {/*    render={({ field }) => (*/}
                    {/*      <>*/}
                    {/*        <FormGroup check row {...field}>*/}
                    {/*          <Row className="mb-4">*/}
                    {/*            <Col xs={12}>*/}
                    {/*              <Row className="">*/}
                    {/*                <div className="form-check mb-2 mt-2 ">*/}
                    {/*                  <input*/}
                    {/*                    className="form-check-input"*/}
                    {/*                    type="radio"*/}
                    {/*                    name="PaymenyType"*/}
                    {/*                    id="deposit"*/}
                    {/*                    value="deposit"*/}
                    {/*                    checked={*/}
                    {/*                      watch(`PaymenyType`) === "deposit"*/}
                    {/*                    }*/}
                    {/*                  />*/}
                    {/*                  <label*/}
                    {/*                    className="form-check-label"*/}
                    {/*                    htmlFor="deposit"*/}
                    {/*                  >*/}
                    {/*                    <Col className="px-1 text-muted">*/}
                    {/*                      <Row>*/}
                    {/*                        <h5 className="text-muted">*/}
                    {/*                          {`Acconto ${getSymbolFromCurrency(*/}
                    {/*                            tour?.currency*/}
                    {/*                          )} ${(*/}
                    {/*                            cart?.totalPax * tour?.deposit*/}
                    {/*                          ).toFixed(2)}`}*/}
                    {/*                        </h5>*/}
                    {/*                      </Row>*/}
                    {/*                      <Row>*/}
                    {/*                        <p className="ps-2">*/}
                    {/*                          Paga ora un acconto con la carta*/}
                    {/*                          di credito per confermare la*/}
                    {/*                          prenotazione*/}
                    {/*                        </p>*/}
                    {/*                      </Row>*/}
                    {/*                      <Row>*/}
                    {/*                        <p className="ps-2">*/}
                    {/*                          /!*FIXME: this should be checked with the session.start date and if there are less than 15 days, it will be shown and there is no chance to go ahead with just the deposit amount.*!/*/}
                    {/*                          Non é possibile selezionare il*/}
                    {/*                          pagamento del solo acconto perché*/}
                    {/*                          mancano meno di 15 giorni alla*/}
                    {/*                          partenza del viaggio.*/}
                    {/*                        </p>*/}
                    {/*                      </Row>*/}
                    {/*                    </Col>*/}
                    {/*                  </label>*/}
                    {/*                </div>*/}
                    {/*              </Row>*/}
                    {/*            </Col>*/}
                    {/*            <Col xs={12}>*/}
                    {/*              <Row>*/}
                    {/*                <div className="form-check mb-2 mt-2 ">*/}
                    {/*                  <input*/}
                    {/*                    className="form-check-input"*/}
                    {/*                    type="radio"*/}
                    {/*                    name="PaymenyType"*/}
                    {/*                    disabled*/}
                    {/*                    id="reservation"*/}
                    {/*                    value="full"*/}
                    {/*                    checked={*/}
                    {/*                      watch(`PaymenyType`) === "full"*/}
                    {/*                    }*/}
                    {/*                  />*/}
                    {/*                  <label*/}
                    {/*                    className="form-check-label"*/}
                    {/*                    htmlFor="reservation"*/}
                    {/*                  >*/}
                    {/*                    <Col className="px-1" xs={12}>*/}
                    {/*                      <Row>*/}
                    {/*                        <h5>*/}
                    {/*                          {`Importo totale ${getSymbolFromCurrency(*/}
                    {/*                            tour?.currency*/}
                    {/*                          )} ${cart?.finalPrice}`}*/}
                    {/*                        </h5>*/}
                    {/*                      </Row>*/}
                    {/*                      <Row>*/}
                    {/*                        <p className="ps-2">*/}
                    {/*                          Paga ora l'intero importo della*/}
                    {/*                          prenotazione con la carta di*/}
                    {/*                          credito.*/}
                    {/*                        </p>*/}
                    {/*                      </Row>*/}
                    {/*                    </Col>*/}
                    {/*                  </label>*/}
                    {/*                </div>*/}
                    {/*              </Row>*/}
                    {/*            </Col>*/}
                    {/*            {errors[`PaymenyType`] ? (*/}
                    {/*              <small*/}
                    {/*                style={{ color: "red" }}*/}
                    {/*                type="invalid"*/}
                    {/*              >*/}
                    {/*                {"Campo richiesto"}*/}
                    {/*              </small>*/}
                    {/*            ) : null}*/}
                    {/*          </Row>*/}
                    {/*        </FormGroup>*/}
                    {/*      </>*/}
                    {/*    )}*/}
                    {/*  />*/}
                    {/*  <hr />*/}
                    {/*</Row>*/}

                    <Row>
                      <Controller
                        name="acceptenceCheck"
                        control={control}
                        className="form-control"
                        rules={{ required: true }}
                        render={({ field }) => (
                          <>
                            <FormGroup check row {...field}>
                              <Col>
                                <div className="">
                                  <div className="form-check mb-2 mt-2 ">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      name="acceptenceCheck"
                                      id="acceptenceCheck"
                                      value="option1"
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="acceptenceCheck"
                                    >
                                      <Col className="px-1">
                                        <Row>
                                          <h5>
                                            Accetazione dei termini e condizioni
                                            *
                                          </h5>
                                        </Row>
                                        <Row>
                                          <p className="ps-2 font-size-12">
                                            Scegliendo di completare questa
                                            prenotazione dichiaro di aver letto
                                            e accetto le <a href={"https://www.weshoot.it/terms"} target={"_blank"}> condizioni generali</a>.
                                          </p>
                                          {errors[`acceptenceCheck`] ? (
                                            <small
                                              style={{ color: "red" }}
                                              type="invalid"
                                            >
                                              {"Campo richiesto"}
                                            </small>
                                          ) : null}
                                        </Row>
                                      </Col>
                                    </label>
                                  </div>
                                </div>
                              </Col>
                            </FormGroup>
                          </>
                        )}
                      />
                    </Row>
                    <Row>
                      <Col xs={12} className="mb-3"></Col>
                    </Row>
                    <Row>
                      <Col xs={12} className="mb-3">
                        <p className="font-size-12">
                          Una volta che la prenotazione é stata processata
                          riceverai una email con la conferma del pagamento e le
                          istruzioni per completare la tua prenotazione.
                        </p>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col xl="4" md={4}>
                <Card className="card-border">
                  <CardBody>
                    <CardImg src={image} style={ {borderRadius: "8px", marginBottom: "16px"}}/>
                    <CardTitle className="mb-3">Riepilogo</CardTitle>

                    {tour && <div style={{textAlign: "center"}}>Dal {moment(tour.start).locale("it").format("DD/MM/YYYY")} al {moment(tour.end).locale("it").format("DD/MM/YYYY")}</div>}


                    <div className="table-responsive">
                      <Table className="table mb-0">
                        <tbody>
                          <tr>
                            <td>{tour?.tour?.title} :</td>
                            {tour?.price && (
                              <td className="text-end summary-col">{`${getSymbolFromCurrency(
                                tour?.currency
                              )} ${tour?.price}`}</td>
                            )}
                          </tr>

                          <tr>
                            <th>Saldo</th>
                            {cart?.finalPrice && (
                              <th className="text-end summary-col">
                                {`${getSymbolFromCurrency(tour?.currency)} ${
                                  cart?.finalPrice
                                }`}
                              </th>
                            )}
                          </tr>
                          <tr>
                            <th>Totale da pagare oggi</th>

                            <th className="text-end summary-col">
                              €1
                            </th>

                          </tr>
                        </tbody>
                      </Table>
                    </div>
                    <button
                      className="btn btn-primary w-100 p-2 d-flex align-items-center justify-content-center"
                      type="submit"
                    >
                      {common?.loading &&
                      common?.loaderName === "UPDATE_CART" ? (
                        <Spinner
                          animation="border"
                          variant="secondary"
                          // size="sm"
                          style={{ height: "18px", width: "18px" }}
                        />
                      ) : "Procedi"}
                    </button>
                  </CardBody>
                </Card>
                <Row>
                  <div className="d-flex align-items-center justify-content-center flex-column">
                    <h5 className="pointer">Hai bisogno di aiuto? </h5>
                    <button
                        className="btn btn-default   w-100"
                        onClick={() => {
                          customerly.open();
                        }}
                    >
                      Contattaci{" "}
                    </button>
                  </div>
                </Row>

                {/* <Row>
                <Col>
                  <Card className="card-border bg-light mt-3">
                    <CardBody className="p-1">
                      <div className="table-responsive">
                        <Table className="table mb-0">
                          <tbody className="d-flex align-items-center justify-content-center flex-column g-0">
                            <tr className="d-flex align-items-center g-0">
                              <td>1</td>
                              <td>Complete your booking by</td>
                            </tr>

                            <tr className="d-flex align-items-center">
                              <td>
                                <h5 className="text-danger m-0">08 : 18</h5>
                              </td>
                              <td>
                                <button
                                  type="button"
                                  class="btn btn-outline-secondary"
                                >
                                  Restart the timer{" "}
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row> */}
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AccountData;
