import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap";
import getSymbolFromCurrency from "currency-symbol-map";

const ConfirmPayModel = (props) => {
  const { isOpen, toggle, cart, handleConfirmPayment } = props;
  //   console.log("cart", props);

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal-content">
        <ModalHeader toggle={toggle}>You want to pay Now?</ModalHeader>
        <ModalBody>
          <p className="mb-2">
            Cart id: <span className="text-primary">{cart?.id}</span>
          </p>
          <p className="mb-4">
            Billing Name:{" "}
            <span className="text-primary">{`${cart?.user?.firstName} ${cart?.user?.lastName}`}</span>
          </p>

          <div className="table-responsive">
            <Table className="table align-middle table-nowrap">
              <thead>
                {cart && cart.packages && cart.packages.length > 0 && (
                  <tr>
                    <th scope="col">Package Name</th>
                    <th scope="col">Price</th>
                  </tr>
                )}
              </thead>
              <tbody>
                {cart &&
                  cart.packages &&
                  cart.packages.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <div>
                            <h5 className="text-truncate font-size-14">
                              {item?.title}
                            </h5>
                            {/* <p className="text-muted mb-0">$ 225 x 1</p> */}
                          </div>
                        </td>
                        <td>{`${getSymbolFromCurrency(
                          cart?.session?.currency
                        )} ${item?.price}`}</td>
                      </tr>
                    );
                  })}

                <tr>
                  <td colSpan="1">
                    <h6 className="m-0 text-end">Sub Total:</h6>
                  </td>
                  <td>{`${getSymbolFromCurrency(cart?.session?.currency)} ${
                    cart?.totalPrice
                  }`}</td>
                </tr>

                <tr>
                  <td colSpan="1">
                    <h6 className="m-0 text-end">Total:</h6>
                  </td>
                  <td>{`${getSymbolFromCurrency(cart?.session?.currency)} ${
                    cart?.finalPrice
                  }`}</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="secondary" onClick={toggle}>
            Cancel
          </Button>
          <Button
            type="button"
            color="secondary"
            onClick={()=>handleConfirmPayment()}
          >
            Confirm
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  );
};

ConfirmPayModel.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
};

export default ConfirmPayModel;
