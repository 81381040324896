import {
  SET_BOOKING_DATA_LOCAL,
  GET_TOUR,
  SET_TOUR_LOCAL,
  SET_CART_LOCAL,
  UPDATE_CART_DATA,
  ADD_TRAVELLERS,
  SET_TRAVELLERS_LOCAL,
  SET_USER,
  SET_USER_LOCAL,
  APPLY_COUPAN,
  SET_APPLY_COUPAN,
  ADD_PAYMENT,
  VERIFY_USER,
  SET_VERIFIED_USER,
  SET_BOOKING_USER,
  LOGIN_BOOKING_USER,
  INIT_CART_LOCAL,
  GET_ME,
} from "./actionTypes";

export function getMe() {
  return {
    type: GET_ME
  };
}

export function setBookingDataLocal(payload) {
  return {
    type: SET_BOOKING_DATA_LOCAL,
    payload: payload,
  };
}
export function initCartLocal(payload) {
  return {
    type: INIT_CART_LOCAL,
    payload: payload,
  };
}
export function verifyUser(payload) {
  return {
    type: VERIFY_USER,
    payload: payload,
  };
}
export function setVerifyUser(payload) {
  return {
    type: SET_VERIFIED_USER,
    payload: payload,
  };
}

export function getTour(id) {
  return {
    type: GET_TOUR,
    payload: id,
  };
}

export const setTourLocal = (payload) => ({
  type: SET_TOUR_LOCAL,
  payload,
});

export const setCartLocal = (payload) => ({
  type: SET_CART_LOCAL,
  payload,
});

export const updateCart = (payload) => ({
  type: UPDATE_CART_DATA,
  payload,
});
export const addTravellers = (payload) => ({
  type: ADD_TRAVELLERS,
  payload,
});
export const setTravellersLocal = (payload) => ({
  type: SET_TRAVELLERS_LOCAL,
  payload,
});
export const saveUser = (payload) => ({
  type: SET_USER,
  payload,
});

export const saveUserLocal = (payload) => ({
  type: SET_USER_LOCAL,
  payload,
});

export function applyCoupan(payload) {
  return {
    type: APPLY_COUPAN,
    payload: payload,
  };
}
export function setCoupanLocal(payload) {
  return {
    type: SET_APPLY_COUPAN,
    payload: payload,
  };
}
export function addPayment(payload) {
  return {
    type: ADD_PAYMENT,
    payload: payload,
  };
}

export function loginBookingUser(payload) {
  return {
    type: LOGIN_BOOKING_USER,
    payload: payload,
  };
}
export function setBookingUserLocal(payload) {
  return {
    type: SET_BOOKING_USER,
    payload: payload,
  };
}
