import React from 'react'
import {Redirect} from 'react-router-dom'


// Pages File Manager
// Pages Calendar
import Calendar from '../pages/Calendar/index'

// User profile
import UserProfile from '../pages/Authentication/UserProfile'

import EcommerceOrders from '../pages/Ecommerce/EcommerceOrders/index'
import EcommerceCustomers from '../pages/Ecommerce/EcommerceCustomers/index'

//Email
//Invoices
// Authentication related pages
import Login from '../pages/Authentication/Login'
import Logout from '../pages/Authentication/Logout'
import Register from '../pages/Authentication/Register'

// Inner Authentication
import Login1 from '../pages/AuthenticationInner/Login'
import Recoverpw from '../pages/AuthenticationInner/Recoverpw'
import ForgetPwd1 from '../pages/AuthenticationInner/ForgetPassword'
import ConfirmMail from '../pages/AuthenticationInner/page-confirm-mail'
import EmailVerification from '../pages/AuthenticationInner/auth-email-verification'

// Dashboard
import Dashboard from '../pages/Dashboard/index'

import Pages404 from '../pages/Utility/pages-404'

//Contacts
//Blog
import Booking from '../pages/Booking'
import TravellersDetails from '../pages/Booking/TravellersDetails'
import AccountData from '../pages/Booking/AccountData'
import Payment from '../pages/Booking/Payment'
import EcommerceShops from '../pages/Ecommerce/EcommerceShops'
import EcommerceAddProduct from '../pages/Ecommerce/EcommerceAddProduct'
import BookingComplete from '../pages/Booking/BookingComplete'
import ForgetPasswordPage from '../pages/Authentication/ForgetPassword'

const authProtectedRoutes = [
  { isAdmin:true, path: "/admin/dashboard", component: Dashboard },
  { isAdmin:true, path: "/admin/profile", component: UserProfile },
  { isAdmin:true, path: "/admin/calendar", component: Calendar },
  { isAdmin:false, path: "/users/bookings", component: EcommerceOrders },
  { isAdmin:true, path: "/admin/orders", component: EcommerceOrders },
  { isAdmin:true, path: "/admin/ecommerce-customers", component: EcommerceCustomers },
  { isAdmin:true, path: "/admin/ecommerce-shops", component: EcommerceShops },
  { isAdmin:true, path: "/admin/ecommerce-add-product", component: EcommerceAddProduct },
  { isAdmin:true, path: "/", component: () => <Redirect to="/login" /> },
  {  isAdmin:true, path: "*", exact: true, component: () => <Redirect to="/login" /> },
];

const publicRoutes = [

  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPasswordPage },
  { path: "/register", component: Register },
  { path: "/pages-404", component: Pages404 },
  { path: "/pages-login", component: Login1 },
  { path: "/page-recoverpw", component: Recoverpw },
  { path: "/pages-forgot-pwd", component: ForgetPwd1 },
  { path: "/page-confirm-mail", component: ConfirmMail },
  { path: "/auth-email-verification", component: EmailVerification },
];

const bookingRoutes = [
  { path: "/account-data/:id", component: AccountData },
  { path: "/payment/:id", component: Payment },
  { path: "/booking/:id", component: Booking },
  { path: "/booking-completed", component: BookingComplete },
  // { path: "/booking", component: Booking },
  { path: "/travellers-details/:id", component: TravellersDetails },
];
export { authProtectedRoutes, publicRoutes, bookingRoutes };
