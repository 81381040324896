import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import SimpleBar from "simplebar-react";
import MetisMenu from "metismenujs";
import { Link, withRouter, useLocation } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { getLoggedInUser } from "../../store/auth/login/actions";

const SidebarContent = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.Login?.user);

  const refDiv = React.useRef();

  useEffect(() => {
    initMenu();
    dispatch(getLoggedInUser());
  }, []);

  const initMenu = () => {
    new MetisMenu("#side-menu");

    let matchingMenuItem = null;
    const ul = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    for (let i = 0; i < items.length; ++i) {
      if (location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  };

  const scrollElement = (item) => {
    setTimeout(() => {
      if (refDiv.current !== null) {
        if (item) {
          const currentPosition = item.offsetTop;
          if (currentPosition > window.innerHeight) {
            if (refDiv.current)
              refDiv.current.getScrollElement().scrollTop =
                currentPosition - 300;
          }
        }
      }
    }, 300);
  };

  const activateParentDropdown = (item) => {
    item.classList.add("active");
    const parent = item.parentElement;

    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  };

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={refDiv}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {user?.role?.type !== "user" && (
              <li>
                <Link
                  to={`/${
                    user?.role?.type === "user" ? "user" : "admin"
                  }/dashboard`}
                >
                  <i className="bx bx-home-circle" />
                  <span>{t("Dashboards")}</span>
                </Link>
              </li>
            )}
            {user?.role?.type !== "user" && (
              <>
                <li className="menu-title">{t("Organizer")}</li>

                <li>
                  <Link to="/admin/calendar" className="">
                    <i className="bx bx-calendar" />
                    <span>{t("Calendar")}</span>
                  </Link>
                </li>
              </>
            )}
            {user?.role?.type === "user" && (
              <li>
                <Link to={`/users/bookings`}>
                  <i className="mdi mdi-airplane-takeoff" />

                  <span>{t("Trips")}</span>
                </Link>
              </li>
            )}
            {user?.role?.type !== "user" && (
              <li>
                <Link to="/#" className="has-arrow">
                  <i className="mdi mdi-airplane-takeoff" />
                  <span>{t("Trips")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link
                      to={`/${
                        user?.role?.type === "user"
                          ? "user/my-trip"
                          : "admin/orders"
                      }`}
                    >
                      <i className="mdi mdi-airplane-takeoff" />

                      <span>{t("Orders")}</span>
                    </Link>
                  </li>
                  {user?.role?.type !== "user" && (
                    <>
                      <li>
                        <Link to="/admin/ecommerce-customers">
                          {t("Customers")}
                        </Link>
                      </li>
                      <li>
                        <Link to="/admin/ecommerce-shops">{t("Coaches")}</Link>
                      </li>
                      <li>
                        <Link to="/admin/ecommerce-add-product">
                          {t("Add Product")}
                        </Link>
                      </li>

                      <li>
                        <Link to="/admin/tasks-list">{t("Upcoming")}</Link>
                      </li>
                    </>
                  )}
                </ul>
              </li>
            )}
            {user?.role?.type !== "user" && (
              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bxs-user-detail" />
                  <span>{t("Customers")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/admin/contacts-list">
                      {t("Lead List")}{" "}
                      <span className="badge rounded-pill bg-danger float-end">
                        10
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/contacts-profile">{t("Profile")}</Link>
                  </li>
                </ul>
              </li>
            )}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  type: PropTypes.string,
};

export default withRouter(withTranslation()(SidebarContent));
