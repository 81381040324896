import {
  SET_LOADER,
  CLEAR_LOADER,
  SET_MESSAGE,
  CLEAR_MESSAGE,
  CLEAR_LOADER_WITH_REDIRECT
} from "./actionTypes";

export function setLoader(payload) {
  return {
    type: SET_LOADER,
    payload: payload,
  };
}
export function clearLoader() {
  return {
    type: CLEAR_LOADER,
  };
}
export function clearLoaderWithRedirect() {
  return {
    type: CLEAR_LOADER_WITH_REDIRECT,
  };
}
export function setMessage(payload) {
  return {
    type: SET_MESSAGE,
    payload: payload,
  };
}
export function clearMessage() {
  return {
    type: CLEAR_MESSAGE,
  };
}
