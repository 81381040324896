import React, {useEffect} from 'react'
import {Checkmark} from 'react-checkmark'
import {Col, Container, Row} from 'reactstrap'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory, useParams} from 'react-router-dom'
import {useForm} from 'react-hook-form'
import ReactGA from 'react-ga4'

const Booking = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useForm();
  const tour = useSelector((state) => state?.booking?.tour);
  useEffect(() => {
    try {
      localStorage.removeItem("weshoot-cart-id");
      localStorage.removeItem("weshoot-session-id");
      localStorage.removeItem("weshoot-sessionId");
      localStorage.clear()
    } catch (e) {}
  }, []);

  ReactGA.send({ hitType: "pageview", page: "/cooking-completed" });

  return (
    <React.Fragment>
      <div className="page-content full-height padding-top  ">
        <Container fluid>
          <Row className="d-flex align-items-center justify-content-center">
            <Col xs={12} md={6} lg={6} xl={6}>
              <div className="d-flex align-items-center justify-content-center flex-column ">
                <Checkmark size="xLarge" />
                <h4 className="pt-4 text-center">
                  Prepara l'attrezzatura, noi portiamo gli amici!
                </h4>
              </div>
            </Col>
          </Row>
          <Row className="d-flex align-items-center justify-content-center">
            <Col xs={12} md={6} lg={6} xl={6}>
              <div className="d-flex align-items-center justify-content-center flex-column ">
                <a
                  href={
                    process.env.REACT_APP_STATIC_SITE_URL ||
                    "https://www.weshoot.it/viaggi-fotografici/"
                  }
                >
                  Condividi con un amico
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Booking;
