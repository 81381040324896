import axios from "axios";
import { del, get, post, put } from "./api_helper";


export const userLogin = (data) => post(`/auth/local`, data);
export const currenUser = (data) =>
  get(`/users/me`, {
    headers: {
      authorization: ` Bearer ${localStorage.getItem("weshoot-jwt-token")}`,
    },
  });