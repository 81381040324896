import React, {useEffect, useState} from 'react'
import {
  Card,
  CardBody,
  CardImg,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
  Spinner,
  Table,
} from 'reactstrap'
import {loadStripe} from '@stripe/stripe-js'
import {CardElement, Elements, useElements, useStripe} from '@stripe/react-stripe-js'
import Amex from '../../assets/images/icons/amex-ico.svg'
import Visa from '../../assets/images/icons/visa-ico.svg'
import stripes from '../../assets/images/icons/stripe-logo.svg'
import master from '../../assets/images/icons/mastercard-ico.svg'
import {useDispatch, useSelector} from 'react-redux'
import getSymbolFromCurrency from 'currency-symbol-map'
import {Controller, useForm} from 'react-hook-form'
import {addPayment} from '../../store/booking/actions'
import {useHistory, useParams} from 'react-router-dom'
import moment from 'moment'
import ReactPixel from 'react-facebook-pixel'
import ReactGA from 'react-ga4'

const stripePromise = loadStripe("pk_live_aaXbdI5mg3oZfyM3cglcsGg600BDiA7eQD");

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};
const Payment = () => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();
  const booking = useSelector((state) => state?.booking);
  const cart = useSelector((state) => state?.booking?.cart);
  const tour = useSelector((state) => state?.booking?.tour);
  const common = useSelector((state) => state?.common);
  const [redirect, setRedirect] = useState(false);
  const [loadingType, setLoadingType] = useState(null);
  const image = tour?.tour?.image?.formats?.medium?.url;
  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => submitData(data);
  const submitData = async (data) => {
    try {
      if (elements == null) {
        return;
      }
      let card = elements.getElement(CardElement);
      setLoadingType("PAY");
      const result = await stripe.createToken(card);
      dispatch(
        addPayment({
          id: cart?.user?.id,
          body: {
            token: result.token.id,
            amount:
              cart?.PaymenyType === "full"
                ? cart?.finalPrice * 100
                : (cart?.totalPax * tour?.deposit).toFixed(2) * 100,
            cart: cart?.id,
            wiretransfer: false,
          },
        })
      );

      try {

        let finalPrice = cart?.PaymenyType === "full"
            ? cart?.finalPrice
            : (cart?.totalPax * tour?.deposit).toFixed(2);

        var dataLayer = (window.dataLayer = window.dataLayer || []);

        dataLayer.push({ ecommerce: null });
        dataLayer.push({
          event: "purchase",
          ecommerce: {
            transaction_id: result.token.id,
            value: finalPrice,
            currency: "EUR",
            items: [
              {
                item_name:  tour?.tour?.title,
                item_id: tour?.tour?.id,
                price:finalPrice,
                brand: "WeShoot",
                category: "Viaggi Fotografici",
                quantity: cart?.totalPax,
              },
            ],
          },
        });

        ReactPixel.track('Purchase', {
          value: finalPrice,
          currency: 'EUR',
          content_type: 'product',
          content_ids: tour?.tour?.id
        });

      } catch (err) {
        console.log("Error", err);
      }

      setRedirect(true);
    } catch (e) {
      setLoadingType(null);
    }
  };
  const handleSubmitWithOldcard = async () => {
    setLoadingType("OLD_CARD");

    dispatch(
      addPayment({
        id: cart?.user?.id,
        body: {
          token: null,
          amount:
            cart?.PaymenyType === "full"
              ? cart?.finalPrice * 100
              : (cart?.totalPax * tour?.deposit).toFixed(2) * 100,
          cart: cart?.id,
          wiretransfer: false,
        },
      })
    );
    setRedirect(true);
  };
  const handleSubmitPayLater = async () => {
    setLoadingType("PAY_LATER");

    dispatch(
      addPayment({
        id: cart?.user?.id,
        body: {
          token: null,
          amount: cart?.finalPrice,
          cart: cart?.id,
          wiretransfer: true,
        },
      })
    );
    setRedirect(true);
  };
  useEffect(() => {
    if (redirect && !common?.loading && common?.redirect) {
      history.push(`/booking-completed`);
    }
  }, [redirect, common?.loading, common?.redirect]);
  // booking-completed
  React.useEffect(() => {
    if (id) {
      if (!cart) {
        history.push(`/booking/${id}`);
      } else if (!booking?.user?.user?.id) {
        history.push(`/booking/${id}`);
      }
    } else {
      history.push("/booking");
    }
  }, []);

  ReactGA.send({ hitType: "pageview", page: "/payment-info" });

  return (
    <React.Fragment>
      <div className="page-content padding-top  ">
        <Container fluid className="container-top">
          <Row>
            <Col>
              <h4>Pagamento della prenotazione</h4>
            </Col>
          </Row>
          {booking?.user?.user?.cardId && (
            <>
              <Row>
                <Col
                  xs={12}
                  className="mb-3 d-flex align-items-center justify-content-center"
                >
                  <button
                    className="btn btn-primary  p-2 d-flex align-items-center justify-content-center"
                    type="submit"
                    onClick={() => handleSubmitWithOldcard()}
                    style={{ minWidth: "185px", minHeight: "37px" }}
                  >
                    {common?.loading &&
                    common?.loaderName === "UPDATE_CART" &&
                    loadingType === "OLD_CARD" ? (
                      <Spinner
                        animation="border"
                        variant="secondary"
                        // size="sm"
                        style={{ height: "18px", width: "18px" }}
                      />
                    ) : (
                      `Procedi con la carta ${booking?.user?.user?.cardLastFourDigit}`
                    )}
                  </button>
                </Col>
              </Row>
              <Row>
                <Col xs={12} className="mb-3">
                  <p className="  p-2 d-flex align-items-center justify-content-center">
                    Oppure
                  </p>
                </Col>
              </Row>
            </>
          )}
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col xl="8" md={8} sm={12} xs={12}>
                 <Row>
                <Col>
                  <div style={{ marginBottom: "16px", marginTop: "16px" }}>Il pagamento di 1€ é solo a scopo di conferma della prenotazione. Il saldo dovrà essere versato tramite bonifico bancario.</div>

                </Col>
              </Row>


                <Row>
                  <div className="mb-3">
                    <Label htmlFor="formrow-name-Input">Nome sulla carta</Label>

                    <Controller
                      name="name"
                      control={control}
                      className="form-control"
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Input
                          {...field}
                          type="text"
                          className="form-control"
                          id="formrow-name-Input"
                          placeholder="Inserisci il nome sulla carta"
                          invalid={errors.name ? true : false}
                        />
                      )}
                    />
                    {errors.name ? (
                      <FormFeedback type="invalid">
                        {"This is required."}
                      </FormFeedback>
                    ) : null}
                  </div>

                  <Row>
                    <Col xs={12}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-firstname-Input">
                          Inserisci i dettagli della tua carta
                        </Label>
                        <CardElement options={CARD_ELEMENT_OPTIONS} />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={5} xs={12}>
                      <Row>
                        <Col
                          style={{ maxWidth: "fit-content" }}
                          className="pe-0"
                        >
                          <p>Pagamenti sicuri con </p>
                        </Col>

                        <Col className="p-0">
                          <img src={stripes} width="60px" height="20px" />
                        </Col>
                      </Row>
                    </Col>
                    <Col md={7} xs={12}>
                      <Row>
                        <Col className="pe-0 text-end">
                          <p>Pagamenti accettati</p>
                        </Col>

                        <Col
                          style={{ maxWidth: "fit-content" }}
                          className="pe-0"
                        >
                          <img src={Amex} width="40px" height="20px" />
                        </Col>

                        <Col
                          style={{ maxWidth: "fit-content" }}
                          className="pe-0"
                        >
                          <img src={master} width="40px" height="20px" />
                        </Col>

                        <Col style={{ maxWidth: "fit-content" }}>
                          <img src={Visa} width="40px" height="20px" />
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <p className="mt-3">
                        Quando la prenotazione é stata processata, riceverai una
                        email con la conferma del pagamento e le istruzioni.
                      </p>
                    </Col>
                  </Row>
                </Row>
              </Col>
              <Col xl="4" md={4}>
                <Card className="card-border">
                  <CardBody>
                    <CardImg
                      src={image}
                      style={{ borderRadius: "8px", marginBottom: "16px" }}
                    />
                    <CardTitle className="mb-3">Riepilogo</CardTitle>
                    {tour && (
                      <div style={{ textAlign: "center" }}>
                        Dal{" "}
                        {moment(tour.start).locale("it").format("DD/MM/YYYY")}{" "}
                        al {moment(tour.end).locale("it").format("DD/MM/YYYY")}
                      </div>
                    )}
                    <div className="table-responsive">
                      <Table className="table mb-0">
                        <tbody>
                          <tr>
                            <td>{tour?.tour?.title} :</td>
                            {tour?.price && (
                              <td className="text-end summary-col">{`${getSymbolFromCurrency(
                                tour?.currency
                              )} ${tour?.price}`}</td>
                            )}
                          </tr>

                          <tr>
                            <th>Saldo</th>
                            {cart?.finalPrice && (
                              <th className="text-end summary-col">
                                {`${getSymbolFromCurrency(tour?.currency)} ${
                                  cart?.finalPrice
                                }`}
                              </th>
                            )}
                          </tr>
                          <tr>
                            <th>Importo da pagare ora</th>
                            {cart?.finalPrice && (
                              <th className="text-end summary-col">
                                {`${getSymbolFromCurrency(tour?.currency)} ${
                                  cart?.PaymenyType === "full"
                                    ? cart?.finalPrice
                                    : (cart?.totalPax * tour?.deposit).toFixed(
                                        0
                                      )
                                }`}
                              </th>
                            )}
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                    <button
                      className="btn btn-primary w-100 p-2 d-flex align-items-center justify-content-center"
                      type="submit"
                    >
                      {common?.loading &&
                      common?.loaderName === "UPDATE_CART" &&
                      loadingType === "PAY" ? (
                        <Spinner
                          animation="border"
                          variant="secondary"
                          // size="sm"
                          style={{ height: "18px", width: "18px" }}
                        />
                      ) : (
                        `Conferma la prenotazione pagando ${
                          cart?.totalPrice &&
                          getSymbolFromCurrency(tour?.currency)
                        } ${
                          cart?.PaymenyType === "full"
                            ? cart?.finalPrice
                            : (cart?.totalPax * tour?.deposit).toFixed(0)
                        }`
                      )}
                    </button>
                    {/*<span className="d-flex align-items-center justify-content-center p-2">*/}
                    {/*  OR*/}
                    {/*</span>*/}
                    {/*<span*/}
                    {/*  className="btn btn-primary w-100 p-2 d-flex align-items-center justify-content-center"*/}
                    {/*  onClick={() => handleSubmitPayLater()}*/}
                    {/*>*/}
                    {/*  {common?.loading &&*/}
                    {/*  common?.loaderName === "UPDATE_CART" &&*/}
                    {/*  loadingType === "PAY_LATER" ? (*/}
                    {/*    <Spinner*/}
                    {/*      animation="border"*/}
                    {/*      variant="secondary"*/}
                    {/*      // size="sm"*/}
                    {/*      style={{ height: "18px", width: "18px" }}*/}
                    {/*    />*/}
                    {/*  ) : (*/}
                    {/*    `Pay Later`*/}
                    {/*  )}*/}
                    {/*</span>*/}
                  </CardBody>
                </Card>
                <Row>
                  <div className="d-flex align-items-center justify-content-center flex-column">
                    <h5 className="pointer">Hai bisogno di aiuto? </h5>
                    <button
                      className="btn btn-default   w-100"
                      onClick={() => {
                        customerly.open();
                      }}
                    >
                      Contattaci{" "}
                    </button>
                  </div>
                </Row>

                {/* <Row>
                <Col>
                  <Card className="card-border bg-light mt-3">
                    <CardBody className="p-1">
                      <div className="table-responsive">
                        <Table className="table mb-0">
                          <tbody className="d-flex align-items-center justify-content-center flex-column g-0">
                            <tr className="d-flex align-items-center g-0">
                              <td>1</td>
                              <td>Complete your booking by</td>
                            </tr>

                            <tr className="d-flex align-items-center">
                              <td>
                                <h5 className="text-danger m-0">08 : 18</h5>
                              </td>
                              <td>
                                <button
                                  type="button"
                                  class="btn btn-outline-secondary"
                                >
                                  Restart the timer{" "}
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row> */}
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  );
};
const PaymentWithStripe = () => {
  return (
    <Elements stripe={stripePromise}>
      <Payment />
    </Elements>
  );
};
export default PaymentWithStripe;
