export const SET_BOOKING_DATA_LOCAL = "SET_BOOKING_DATA_LOCAL";
export const GET_TOUR = "GET_TOUR";
export const SET_TOUR_LOCAL = "SET_TOUR_LOCAL";
export const SET_CART_LOCAL = "SET_CART_LOCAL";
export const UPDATE_CART_DATA = "UPDATE_CART_DATA";
export const ADD_TRAVELLERS = "ADD_TRAVELLERS";
export const SET_TRAVELLERS_LOCAL = "SET_TRAVELLERS_LOCAL";
export const SET_USER = "SET_USER";
export const SET_USER_LOCAL = "SET_USER_LOCAL";
export const APPLY_COUPAN = "APPLY_COUPAN";
export const SET_APPLY_COUPAN = "SET_APPLY_COUPAN";
export const ADD_PAYMENT = "ADD_PAYMENT";
export const SET_VERIFIED_USER = "SET_VERIFIED_USER";
export const VERIFY_USER = "VERIFY_USER";
export const LOGIN_BOOKING_USER = "LOGIN_BOOKING_USER";
export const SET_BOOKING_USER = "SET_BOOKING_USER";
export const INIT_CART_LOCAL = "INIT_CART_LOCAL";
export const GET_ME = "GET_ME";

export const API_FAIL = "API_FAIL";
export const GET_CHARTS_DATA = "GET_CHARTS_DATA";


