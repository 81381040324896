import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {BrowserRouter as Router, Switch} from 'react-router-dom'
import {connect} from 'react-redux'

// Import Routes
import {authProtectedRoutes, bookingRoutes, publicRoutes} from './routes/'
import AppRoute from './routes/route'

// layouts
import VerticalLayout from './components/VerticalLayout/'
import BookingLayout from './components/HorizontalLayout'
import NonAuthLayout from './components/NonAuthLayout'

import './assets/scss/theme.scss'


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.getLayout = this.getLayout.bind(this);
  }

  /**
   * Returns the layout
   */
  getLayout = () => {
    let layoutCls = VerticalLayout;
    return layoutCls;
  };

  render() {
    const Layout = this.getLayout();

    return (
      <React.Fragment>
        <Router>
          <Switch>
            {publicRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
              />
            ))}
            {bookingRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={BookingLayout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
              />
            ))}
            {authProtectedRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={Layout}
                component={route.component}
                key={idx}
                isAdmin={route?.isAdmin}
                isAuthProtected={true}
                exact
              />
            ))}
          </Switch>
        </Router>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
  };
};

App.propTypes = {
  layout: PropTypes.object,
};

export default connect(mapStateToProps, null)(App);
