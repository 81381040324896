import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import {BrowserRouter} from 'react-router-dom'
import './i18n'
import ReactPixel from 'react-facebook-pixel'
import {Provider} from 'react-redux'

import store from './store'
import * as TagManager from 'react-gtm-module'
import ReactGA from 'react-ga4'


//identify if you are on development or production
//when you build your app process.env.NODE_ENV is set to 'production'
const env = process.env.NODE_ENV;

const tagManagerArgs = {
    gtmId: "GTM-MRH76BZ",
    //the below changes GTM values based on whether dev or production
    auth: env === "development"
        ? "<dev ga_auth>"
        : "<live ga_auth>",
    preview: env === "development"
        ? "<dev ga_env>"
        : "<live ga_env>"
};

TagManager.initialize(tagManagerArgs)
ReactGA.initialize("G-DV8LKGMGTY");
ReactGA.send("pageview");

const options = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: false, // enable logs
};
ReactPixel.init('220965505676374', {}, options);

ReactPixel.pageView(); // For tracking page view

const app = (
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
)

ReactDOM.render(app, document.getElementById("root"))
serviceWorker.unregister()