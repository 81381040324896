import axios from "axios";
import { del, get, post, put } from "./api_helper";
import * as url from "./url_helper";

// get Products
export const getTours = (id) => get(`/sessions/details/${id}`);
export const createCart = (data) =>
  post(`/carts`, data?.data, {
    headers: { authorization: ` Bearer ${data?.token}` },
  });
export const updateCart = async (data) =>
  await put(`/carts/${localStorage.getItem("weshoot-cart-id")}`, data, {
    headers: {
      authorization: ` Bearer ${localStorage.getItem("weshoot-token")}`,
    },
  });
export const getCartDetails = (id) =>
  get(`/carts/${id}`, {
    headers: {
      authorization: ` Bearer ${localStorage.getItem("weshoot-token")}`,
    },
  });
export const addTraverller = (data) => post(`/travelers`, data);
export const createUser = (data) => post(`/users`, data);
export const updateUser = (data) =>
  put(`/users/${data?.id}`, data?.body, {
    headers: {
      authorization: ` Bearer ${localStorage.getItem("weshoot-token")}`,
    },
  });
export const getCoupan = (id) => get(`/coupons/?code=${id}`);
export const createPayment = (data) =>
  post(`/users/payment/${data?.id}`, data?.body, {
    headers: {
      authorization: ` Bearer ${localStorage.getItem("weshoot-token")}`,
    },
  });
export const verifyUser = (data) => post(`/users/verify`, data);
export const loginUser = (data) => post(`/auth/local`, data);
export const createCartPackages = (data) => post(`/cart-packages`, data);
export const updateCartPackages = (data) =>
  put(`/cart-packages/${data?.id}`, data?.body);
export const currenUser = (data) =>
  get(`/users/me`, {
    headers: {
      authorization: ` Bearer ${localStorage.getItem("weshoot-token")}`,
    },
  });
export const sendEmail = (data) => post(`/users/sendemail`, data);
