import { takeEvery, put, call, select } from "redux-saga/effects";
import {
  GET_TOUR,
  UPDATE_CART_DATA,
  ADD_TRAVELLERS,
  SET_USER,
  APPLY_COUPAN,
  ADD_PAYMENT,
  VERIFY_USER,
  LOGIN_BOOKING_USER,
  GET_ME,
} from "./actionTypes";
import {
  setTourLocal,
  setCartLocal,
  saveUserLocal,
  setCoupanLocal,
  setVerifyUser,
  setBookingUserLocal,
} from "./actions";
import {
  setLoader,
  clearLoader,
  setMessage,
  clearLoaderWithRedirect,
} from "../common/actions";
import {
  getTours,
  createCart,
  getCartDetails,
  updateCart,
  addTraverller,
  createUser,
  updateUser,
  getCoupan,
  createPayment,
  verifyUser,
  loginUser,
  currenUser,
  createCartPackages,
  updateCartPackages,
  sendEmail,
} from "../../helpers/booking_helper";

function* getTourDetails({ payload }) {
  try {
    if (payload) {
      if (localStorage.getItem("weshoot-session-id") == payload) {
        if (localStorage.getItem("weshoot-cart-id")) {
          const cartDetails = yield call(
            getCartDetails,
            localStorage.getItem("weshoot-cart-id")
          );
          yield put(setCartLocal(cartDetails));
        }
        const response = yield call(getTours, payload);

        yield put(setTourLocal(response));
      } else {
        const response = yield call(getTours, payload);

        if (response) {
          localStorage.setItem("weshoot-session-id", payload);
          localStorage.setItem("weshoot-sessionId", response.id);
        }
        console.log({ response });
        // const responseCart = yield call(createCart, {
        //   paymenyType: "full",
        //   discountAmount: 0,
        //   isCompleted: false,
        //   session: response?.id,
        // });

        // if (responseCart) {
        //   localStorage.setItem("weshoot-cart-id", responseCart?.id);
        // }
        // localStorage.setItem("weshoot-cart-id", responseCart?.id);
        yield put(setTourLocal(response));
        // yield put(setCartLocal(responseCart));
      }
    }
  } catch (error) {
    yield put(
      setMessage({
        message: error?.message,
        showMessage: true,
        severity: "error",
      })
    );
  }
}

function* updateCartDetails({ payload }) {
  try {
    yield put(
      setLoader({
        loading: true,
        loaderName: "UPDATE_CART",
      })
    );
    if (localStorage.getItem("isLogedIn")) {
      console.log("loggged in");
      if (!localStorage.getItem("weshoot-cart-id")) {
        const responseCart = yield call(createCart, {
          paymenyType: "full",
          discountAmount: 0,
          isCompleted: false,
          session: localStorage.getItem("weshoot-sessionId"),
          // session: null,
        });
        if (responseCart) {
          localStorage.setItem("weshoot-cart-id", responseCart?.id);
        }
      }
    } else {
      console.log("not logged in");
    }
    if (localStorage.getItem("weshoot-cart-id")) {
      //here create new cart-packages or update and link this cart
      let cartArr = [];
      for (let index = 0; index < payload?.cart_packages?.length; index++) {
        const element = payload?.cart_packages[index];

        const responseCartPackages = element?.id
          ? yield call(updateCartPackages, {
              id: element?.id,
              body: {
                quantity: element?.quantity,
                cart: localStorage.getItem("weshoot-cart-id"),
                package: element?.package,
              },
            })
          : yield call(createCartPackages, {
              quantity: element?.quantity,
              cart: localStorage.getItem("weshoot-cart-id"),
              package: element?.package,
            });

        cartArr.push(responseCartPackages?.id);
      }
      let p = { ...payload, cart_packages: [...cartArr] };
      const response = yield call(updateCart, p);
      yield put(setCartLocal(response));
    } else {
      yield put(setCartLocal(payload));
    }
    yield put(clearLoaderWithRedirect());
  } catch (error) {
    yield put(clearLoader());
    yield put(
      setMessage({
        message: error?.message,
        showMessage: true,
        severity: "error",
        redirect: false,
      })
    );
  }
}
function* addTravellers({ payload }) {
  const state = yield select();
  let d = { ...state?.booking?.cart };
  yield put(
    setLoader({
      loading: true,
      loaderName: "UPDATE_CART",
    })
  );
  try {
    let arr = [];
    for (let index = 0; index < payload.traveller.length; index++) {
      const element = payload.traveller[index];
      const response = yield call(addTraverller, element);
      arr.push(response);
    }
    let localUser = state?.booking?.user?.user?.id;

    let userResponse = null;
    let randomPassword = Math.random().toString(36).slice(6);
    if (!localStorage.getItem("weshoot-token")) {
      userResponse = yield call(createUser, {
        ...payload.user,
        password: randomPassword,
        username: payload.user?.email,
        role: 4,
      });

      //login user

      let loginObj = {
        identifier: payload.user?.email,
        password: randomPassword,
      };
      const loginResponse = yield call(loginUser, loginObj);
      localStorage.setItem("weshoot-token", loginResponse?.jwt);
      localStorage.setItem("weshoot-user", loginResponse?.user?.id);
      localStorage.setItem("isLogedIn", true);
      yield put(setBookingUserLocal(loginResponse));

      const responseCart = yield call(createCart, {
        token: loginResponse?.jwt,
        data: {
          ...d,
          paymenyType: "full",
          discountAmount: 0,
          isCompleted: false,
          user: userResponse?.id,
          travelers: [...arr],
          // session: null,
          session: localStorage.getItem("weshoot-sessionId"),
        },
      });
      localStorage.setItem("weshoot-cart-id", responseCart?.id);
      let cartArr = [];
      for (let index = 0; index < d?.cart_packages?.length; index++) {
        const element = d?.cart_packages[index];

        const responseCartPackages = yield call(createCartPackages, {
          quantity: element?.quantity,
          cart: localStorage.getItem("weshoot-cart-id"),
          package: element?.package,
        });
        cartArr.push(responseCartPackages?.id);
      }
      let p = { ...responseCart, cart_packages: [...cartArr] };
      const response2 = yield call(updateCart, p);
      yield put(setCartLocal(response2));
      yield put(clearLoaderWithRedirect());
    } else {
      const state2 = yield select();
      let d2 = { ...state2?.booking?.cart };
      d2.travelers = [...arr];
      d2.session = localStorage.getItem("weshoot-sessionId");
      console.log("1");
      if (d2?.id) {
        console.log("2");

        const response = yield call(updateCart, d2);
        yield put(setCartLocal(response));
        yield put(clearLoaderWithRedirect());
      } else {
        const responseCart = yield call(createCart, {
          token: localStorage.getItem("weshoot-token"),
          data: {
            ...d2,
            paymenyType: "full",
            discountAmount: 0,
            isCompleted: false,
            user: userResponse?.id,
            travelers: [...arr],
            session: localStorage.getItem("weshoot-sessionId"),
            // session: localStorage.getItem("weshoot-session-id"),
          },
        });
        localStorage.setItem("weshoot-cart-id", responseCart?.id);
        yield put(setCartLocal(responseCart));
        yield put(clearLoaderWithRedirect());
      }
    }

    // yield put(clearLoaderWithRedirect());
  } catch (error) {
    yield put(clearLoader());
    yield put(
      setMessage({
        message: error?.message,
        showMessage: true,
        severity: "error",
      })
    );
  }
}
function* saveUserSaga({ payload }) {
  try {
    yield put(
      setLoader({
        loading: true,
        loaderName: "UPDATE_CART",
      })
    );
    console.log("payload",payload)
    const state = yield select();
    let d = { ...state?.booking?.cart };
    let localUser = state?.booking?.user?.user?.id;
    const u = d?.user;
    let payloadUser = payload.user;
    let id = u?.id || localUser;
    let newp=Math.random().toString(36).substring(2, 6).toUpperCase() + Math.random().toString(36).substring(2, 7);  
    
    const response = id
      ? yield call(updateUser, { id: id, body: payloadUser })
      : yield call(createUser, {
          ...payloadUser,
          role: 4,
          password: newp,
          username: payload?.user?.email,
        });
    console.log("newp",newp)
        const response2 = yield call(sendEmail, {
      password: newp,
      email: "chabidhussain.132@gmail.com",
      // email: payload?.user?.email,
    });
    yield put(saveUserLocal(response));
    d.user = response?.id;
    d.paymenyType = payload?.cartData?.paymenyType;
    d.balanceAmount = payload?.cartData?.balanceAmount;
    d.session = localStorage.getItem("weshoot-sessionId");
    // d.session = localStorage.getItem("weshoot-session-id");
    const response1 = yield call(updateCart, d);
    yield put(setCartLocal(response1));
    yield put(clearLoaderWithRedirect());
  } catch (error) {
    yield put(clearLoader());
    yield put(
      setMessage({
        message: error?.message,
        showMessage: true,
        severity: "error",
      })
    );
    // yield put(getGroupsFail(error))
  }
}
function* applyCoupan({ payload }) {
  try {
    const state = yield select();
    let d = { ...state?.booking?.cart };
    yield put(
      setLoader({
        loading: true,
        loaderName: "APPLY_BTN",
      })
    );

    const response = yield call(getCoupan, payload);
    d.coupon = response[0];
    d.discountAmount = response[0]?.amount;

    // const response1 = yield call(updateCart, d);
    yield put(setCartLocal(d));
    yield put(clearLoader());
    yield put(
      setMessage({
        message:
          response?.length > 0
            ? "Coupan Applied Succefully!"
            : "Invalid Coupan",
        showMessage: true,
        severity: response?.length > 0 ? "success" : "error",
      })
    );
    // window.location.reload();
  } catch (error) {
    yield put(clearLoader());
    yield put(
      setMessage({
        message: error?.message,
        showMessage: true,
        severity: "error",
      })
    );
    // yield put(getGroupsFail(error))
  }
}
function* addPayment({ payload }) {
  try {
    const state = yield select();
    yield put(
      setLoader({
        loading: true,
        loaderName: "UPDATE_CART",
      })
    );

    const response = yield call(createPayment, payload);
    yield put(setCartLocal(response?.cartUpdate));
    yield put(clearLoaderWithRedirect());
  } catch (error) {
    yield put(clearLoader());
    yield put(
      setMessage({
        message: error?.message,
        showMessage: true,
        severity: "error",
      })
    );
  }
}
function* verifyUserSaga({ payload }) {
  try {
    const state = yield select();
    let d = { ...state?.booking?.cart };
    yield put(
      setLoader({
        loading: true,
        loaderName: "VERIFY_USER",
      })
    );

    const response = yield call(verifyUser, payload);
    yield put(setVerifyUser(response));
    yield put(clearLoader());
  } catch (error) {
    yield put(clearLoader());
  }
}
function* loginUserSaga({ payload }) {
  try {
    const state = yield select();
    let d = { ...state?.booking?.cart };
    yield put(
      setLoader({
        loading: true,
        loaderName: "LOGIN_USER",
      })
    );

    const loginResponse = yield call(loginUser, payload);
    localStorage.setItem("weshoot-token", loginResponse?.jwt);
    localStorage.setItem("weshoot-user", loginResponse?.user?.id);
    localStorage.setItem("isLogedIn", true);
    yield put(setBookingUserLocal(loginResponse));

    const responseCart = yield call(createCart, {
      token: loginResponse?.jwt,
      data: {
        ...d,
        paymenyType: "deposit",
        discountAmount: 0,
        isCompleted: false,
        user: loginResponse?.user?.id,

        session: localStorage.getItem("weshoot-sessionId"),
        // session: localStorage.getItem("weshoot-session-id"),
      },
    });
    localStorage.setItem("weshoot-cart-id", responseCart?.id);
    yield put(setCartLocal(responseCart));

    yield put(clearLoader());
  } catch (error) {
    yield put(clearLoader());
    yield put(
      setMessage({
        message: error?.message,
        showMessage: true,
        severity: "error",
      })
    );
  }
}
function* getCurrentLoggedInUser() {
  try {
    yield put(
      setLoader({
        loading: true,
        loaderName: "LOADING_USER",
      })
    );
    const responseUser = yield call(currenUser, null);

    yield put(saveUserLocal(responseUser));

    yield put(clearLoader());
  } catch (error) {
    yield put(clearLoader());
  }
}
function* bookingSaga() {
  yield takeEvery(GET_TOUR, getTourDetails);
  yield takeEvery(UPDATE_CART_DATA, updateCartDetails);
  yield takeEvery(ADD_TRAVELLERS, addTravellers);
  yield takeEvery(SET_USER, saveUserSaga);
  yield takeEvery(APPLY_COUPAN, applyCoupan);
  yield takeEvery(ADD_PAYMENT, addPayment);
  yield takeEvery(VERIFY_USER, verifyUserSaga);
  yield takeEvery(LOGIN_BOOKING_USER, loginUserSaga);
  yield takeEvery(GET_ME, getCurrentLoggedInUser);
}

export default bookingSaga;
