import {
  SET_LOADER,
  CLEAR_LOADER,
  CLEAR_MESSAGE,
  SET_MESSAGE,
  CLEAR_LOADER_WITH_REDIRECT,
} from "./actionTypes";

const INIT_STATE = {
  loading: false,
  loaderName: null,
  redirect: false,
  message: null,
  showMessage: false,
  severity: null,
};

const Booking = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CLEAR_LOADER_WITH_REDIRECT:
      return {
        ...state,
        loading: false,
        loaderName: null,
        redirect: true,
      };
    case SET_LOADER:
      return {
        ...state,
        ...action.payload,
        redirect: false,

      };
    case CLEAR_LOADER:
      return {
        ...state,
        loading: false,
        loaderName: null,
      };
    case CLEAR_MESSAGE:
      return {
        ...state,
        message: false,
        showMessage: null,
        severity: null,
        redirect: false,
      };
    case SET_MESSAGE:
      return {
        ...state,
        ...action.payload,
        
      };
    default:
      return state;
  }
};

export default Booking;
