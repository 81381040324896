import React, {useEffect, useState} from 'react'
import {Card, CardBody, CardImg, CardTitle, Col, Container, Input, InputGroup, Row, Spinner, Table} from 'reactstrap'
import {applyCoupan, getTour, initCartLocal, updateCart} from '../../store/booking/actions'
import {clearLoaderWithRedirect} from '../../store/common/actions'
import {useDispatch, useSelector} from 'react-redux'
import {Link, useHistory, useParams} from 'react-router-dom'
import getSymbolFromCurrency from 'currency-symbol-map'
import {useTranslation} from 'react-i18next'
import moment from 'moment'
import ReactPixel from 'react-facebook-pixel'
import ReactGA from 'react-ga4'


const Booking = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();
  const booking = useSelector((state) => state?.booking?.booking);
  const tour = useSelector((state) => state?.booking?.tour);
  const cart = useSelector((state) => state?.booking?.cart);
  const common = useSelector((state) => state?.common);

  const image = tour?.tour?.image?.formats?.medium?.url;

  const [roomSharing, setRoomSharing] = useState(false);
  const [tourCancel, setTourCanacel] = useState(false);
  const [maleCount, setMaleCount] = useState(1);
  const [maleCountArr, setMaleCountArr] = useState([]);
  const [optionsArr, setOptionsArr] = useState([]);
  const [extraPay, setExtraPay] = useState(0);
  const [dbPackages, setDbpackages] = useState(0);
  const [coupan, setCoupan] = useState("");
  const [redirect, setRedirect] = useState(false);


  function countUP() {
    setMaleCount(maleCount + 1);
    setMaleCountArr([...maleCountArr, maleCount + 1]);
  }

  function countDown() {
    if (maleCount - 1 > -1) {
      setMaleCount(maleCount - 1);
      setMaleCountArr(maleCountArr.splice(0, maleCountArr.length - 1));
    }
  }
  const handleSubmit = async () => {
    let finalPrice = tour?.price * maleCount -
        (cart?.discountAmount || 0 - extraPay) +
        dbPackages;

    if (maleCount > 0) {
      let aarr = [];
      let pkgArr = [];
      let pkgArr2 = [];
      for (let index = 0; index < optionsArr.length; index++) {
        const element = optionsArr[index];
        if (element?.checked) {
          aarr.push(element?.id);
          pkgArr.push(element);
          pkgArr2.push({
            quantity: element?.count,
            package: element?.id,
          });
        }
      }
      if (!cart?.id) {

        dispatch(
            initCartLocal({
              cart_packages: [...pkgArr2],
              packages: [...pkgArr],
              cancellationPolicy: tourCancel,
              noSharingRoom: roomSharing,
              totalPax: maleCount,
              totalPrice: tour?.price * maleCount,
              finalPrice:finalPrice,
              isCompleted: false,
            })
        );
        dispatch(clearLoaderWithRedirect());
      } else {
        dispatch(
            updateCart({
              cart_packages: [...pkgArr2],
              packages: [...aarr],
              cancellationPolicy: tourCancel,
              noSharingRoom: roomSharing,
              totalPax: maleCount,
              totalPrice: tour?.price * maleCount,
              finalPrice:finalPrice,
              isCompleted: false,
            })
        );
      }
      setRedirect(true);
    }

    try {
      var dataLayer = window.dataLayer = window.dataLayer || [];

      dataLayer.push({ ecommerce: null });
      dataLayer.push({
        event: "begin_checkout",
        ecommerce: {
          currencyCode: 'EUR',
          items : [
            {
              item_name: tour?.tour?.title,
              item_id: tour?.tour?.id,
              price: finalPrice,
              item_brand : "WeShoot",
              category : 'Viaggi Fotografici',
            }
          ]
        }
      });


      ReactPixel.track('InitiateCheckout', {
        value: finalPrice,
        currency: 'EUR',
        content_type: 'product',
        content_ids: tour?.tour?.id
      });

    }
    catch (err) {
      console.log("Error", err);
    }

  };

  ReactGA.send({ hitType: "pageview", page: "/booking" });

  useEffect(() => {
    if (redirect && !common?.loading && common?.redirect) {
      history.push(`/travellers-details/${id}`);
    }
  }, [redirect, common?.loading, common?.redirect]);
  const handleApplyCoupan = async () => {
    dispatch(applyCoupan(coupan));
  };

  useEffect(() => {
    if (cart) {
      setMaleCount(cart?.totalPax?cart?.totalPax:1);
      let arr = [];
      for (let index = 0; index < cart?.totalPax; index++) {
        arr.push(index + 1);
      }

      setMaleCountArr([...arr]);
    }
  }, [booking, cart]);

  useEffect(() => {
    dispatch(getTour(id));
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined' && tour){
      let finalPrice = tour?.price * maleCount -
          (cart?.discountAmount || 0 - extraPay) +
          dbPackages;

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({
        event: "add_to_cart",
        'ecommerce': {
          'currencyCode': 'EUR',
          'currency': 'EUR',
          'value': finalPrice,
          'items': [{
            item_name: tour.title,
            item_id:  tour.id,
            price: finalPrice,
            brand:  'WeShoot',
            quantity: 1,
            category: 'Viaggi Fotografici'
          }]
        }
      });

      ReactPixel.track('AddToCart', {
        value: finalPrice,
        currency: 'EUR',
        content_type: 'product',
        content_ids: tour.id
      });
    }
  }, [tour]);



  useEffect(() => {
    if (tour?.packages?.length > 0) {
      let arr = [];
      for (let index = 0; index < tour?.packages.length; index++) {
        let res = findPackages(tour?.packages[index]?.id);
        arr.push({
          checked: res !== -1,
          count: res === -1 ? 0 : cart.cart_packages[res]?.quantity,
          showDetails: false,
          ...tour?.packages[index],
        });
      }
      setOptionsArr([...arr]);
    }
    if (cart?.packages?.length > 0 && cart?.cart_packages?.length > 0) {
      let pay = 0;
      for (let index = 0; index < cart?.packages?.length; index++) {
        const element = cart?.packages[index];
        let indexPkg = cart.cart_packages.findIndex(
          (f) => f?.package === element?.id
        );
        let count = 1;
        if (indexPkg > -1) {
          count = cart.cart_packages[indexPkg]?.quantity || 1;
        }
        pay = pay + element?.price * parseInt(count);
      }
      setDbpackages(pay);
    }
  }, [tour, tour?.packages, cart, cart?.packages]);

  const findPackages = (id) => {
    let indexPkg = -1;
    try {
      indexPkg = cart.cart_packages.findIndex((f) => f?.package === id);
      return indexPkg;
    } catch (e) {
      return indexPkg;
    }
  };
  const handleChangeValue = (value, index, item) => {
    let arr = [...optionsArr];

    if (value) {
      setExtraPay(extraPay + item?.price);
    } else {
      setExtraPay(extraPay - item?.price * parseInt(arr[index].count));
    }
    arr[index] = {
      ...arr[index],
      count: value ? 1 : 0,
      checked: value,
      ...item,
    };
    setOptionsArr([...arr]);
  };
  const handleChangeOpen = (value, index) => {
    let arr = [...optionsArr];
    arr[index] = {
      ...arr[index],

      showDetails: value,
    };
    setOptionsArr([...arr]);
  };
  const handleChangeCount = (value, index, item) => {
    let arr = [...optionsArr];
    let c = parseInt(value) - parseInt(arr[index].count);

    setExtraPay(extraPay + c * item?.price);
    arr[index] = { ...arr[index], count: value };
    setOptionsArr([...arr]);
  };


  return (
    <React.Fragment>
      {id ? (
        <div className="page-content padding-top">
          <Container fluid>
            <Row>
              <Col lx="8" md={8}>
                <Row>
                  <Card className="dark-card p-4">
                    <CardTitle className="mb-3">
                      Per quanti fotografi vuoi prenotare?
                    </CardTitle>
                    <Row>
                      <Col xs={12} className="mb-3">
                        <Row>
                          <Col
                            style={{ maxWidth: "130px" }}
                            className="d-flex align-items-center"
                          >
                            <h5 className="font-size-14 text-truncate mb-0">
                              Fotografo/a
                            </h5>
                          </Col>
                          <Col>
                            <div style={{ width: "120px" }}>
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <button
                                    type="button"
                                    className="btn btn-primary btn-rounded"
                                    onClick={() => {
                                      countDown();
                                    }}
                                  >
                                    -
                                  </button>
                                </div>
                                <Input
                                  type="text"
                                  value={maleCount}
                                  name="travelers"
                                  className="text-center travelers"
                                  readOnly
                                />
                                <div className="input-group-append">
                                  <button
                                    type="button"
                                    className="btn btn-primary btn-rounded"
                                    onClick={() => {
                                      countUP();
                                    }}
                                  >
                                    +
                                  </button>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card>
                </Row>
                {tour && tour.packages.length > 0 && <Row>
                  <Card className="dark-card">
                    <CardBody>
                      <CardTitle className="mb-3">
                        Pacchetti aggiuntivi
                      </CardTitle>
                      {tour &&
                        tour.packages.map((item, index) => {
                          return (
                            <div className="p-0 m-0 my-3 py-2">
                              <Row className="" key={index}>
                                <Col xs={12} md={7} lg={6}>
                                  <div className="form-check form-check-lg mb-3">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      id={`tourCancel-${index}`}
                                      checked={optionsArr[index]?.checked}
                                      onChange={(e) => {
                                        handleChangeValue(
                                          e.target.checked,
                                          index,
                                          item
                                        );
                                      }}
                                    />
                                    <label
                                      className="form-check-label "
                                      htmlFor={`tourCancel-${index}`}
                                    >
                                      {item?.title}
                                    </label>
                                  </div>
                                </Col>
                                <Col xs={12} md={5} lg={6} className="text-end">
                                  <h5>{`+ ${getSymbolFromCurrency(
                                    tour?.currency
                                  )} ${item?.price}`}</h5>
                                </Col>
                              </Row>
                              {optionsArr[index]?.showDetails && (
                                <Row
                                  key={`details-${index}`}
                                  className="text-start "
                                >
                                  <p className="ms-3">{item?.description}</p>
                                </Row>
                              )}
                              <Row
                                key={`description-${index}`}
                                className="d-flex  align-items-center"
                              >
                                <Col xs={12} md={5} xl={5} lg={5}>
                                  {item?.description && (
                                    <div className="form-check form-check-warning  text-danger">
                                      <p
                                        className="pointer"
                                        onClick={() => {
                                          handleChangeOpen(
                                            !optionsArr[index]?.showDetails,
                                            index
                                          );
                                        }}
                                      >
                                        {optionsArr[index]?.showDetails
                                          ? "Ok grazie"
                                          : "Cosa significa?"}
                                      </p>
                                    </div>
                                  )}
                                </Col>
                                {optionsArr[index]?.count !== 0 && maleCount>0&&
                                  optionsArr[index]?.count &&
                                  parseInt(optionsArr[index]?.count) > 0 &&
                                  item?.type !== "single" && (
                                    <Col
                                      xs={12}
                                      md={7}
                                      xl={7}
                                      lg={7}
                                      className="d-flex align-items-center  flex-sm-row justify-content-md-end ms-sm-3 ms-md-0"
                                    >
                                      <div> Per quante persone? </div>

                                      <div className=" ms-2">
                                        <select
                                          id="formrow-InputState"
                                          className="form-control"
                                          value={optionsArr[index]?.count || 1}
                                          onChange={(e) =>
                                            handleChangeCount(
                                              e.target.value,
                                              index,
                                              item
                                            )
                                          }
                                        >
                                          {Array(maleCount)
                                            .fill()
                                            .map((item, index) => {
                                              return (
                                                <option
                                                  value={index + 1}
                                                  // defaultValue={index === 0}
                                                >
                                                   {index + 1} Viaggiatori
                                                </option>
                                              );
                                            })}
                                        </select>
                                      </div>
                                    </Col>
                                  )}
                              </Row>
                            </div>
                          );
                        })}
                    </CardBody>
                  </Card>
                </Row>}

                <Row>
                  <Card className="dark-card">
                    <CardBody>
                      <CardTitle className="mb-3">
                        Garanzia annullamento viaggio per COVID
                      </CardTitle>

                      <p className="text-muted mb-0">
                        In WeShoot ci impegniamo a garantirti la massima
                        flessibilitá e sicurezza durante tutta l'esperienza di
                        viaggio con dei protocolli di sicurezza testati sul
                        campo.
                      </p>
                    </CardBody>
                  </Card>
                </Row>
                <Row>
                  <CardTitle className="mb-3">Hai un coupon?</CardTitle>
                  <Row className="mb-3">
                    <Col xs={12} md={6} lg={6} xl={6} className="mb-3">
                      <InputGroup>
                        <input
                          type="text"
                          placeholder={"Inserisci il tuo codice..."}
                          value={cart?.coupon?.id ? cart?.coupon?.code : coupan}
                          onChange={(e) => setCoupan(e.target.value)}
                          className="form-control"
                        />
                        <div
                          className="input-group-text  d-flex justify-content-center pointer"
                          style={{ width: "80px" }}
                          // onClick={handleApplyCoupan}
                          onClick={!cart?.coupon?.id && handleApplyCoupan}
                        >
                          {common?.loading &&
                          common?.loaderName === "APPLY_BTN" ? (
                            <Spinner
                              animation="border"
                              variant="secondary"
                              style={{ height: "18px", width: "18px" }}
                            />
                          ) : cart?.coupon?.id ? (
                            "Applicato"
                          ) : (
                            "Applica"
                          )}
                        </div>
                      </InputGroup>
                    </Col>
                    <Col xs={12} md={6} lg={6} xl={6} className="mb-3"/>
                  </Row>
                </Row>
              </Col>
              <Col xl="4" md={4}>
                <Card className="card-border">
                  <CardBody>
                    <CardImg src={image} style={ {borderRadius: "8px", marginBottom: "16px"}}/>
                    <CardTitle className="mb-3">Riepilogo</CardTitle>
                    {tour && <div style={{textAlign: "center"}}>Dal {moment(tour.start).locale("it").format("DD/MM/YYYY")} al {moment(tour.end).locale("it").format("DD/MM/YYYY")}</div>}
                    <div className="table-responsive">
                      <Table className="table mb-0">
                        <tbody>
                          <tr>
                            <td>{tour?.tour?.title}:</td>
                            {tour?.price && (
                              <td className="text-end summary-col">{`${getSymbolFromCurrency(
                                tour?.currency
                              )} ${tour?.price}`}</td>
                            )}
                          </tr>

                          <tr>
                            <th>Saldo</th>
                            {tour?.price && (
                              <th className="text-end summary-col">
                                {`${getSymbolFromCurrency(tour?.currency)} ${
                                  tour?.price * maleCount -
                                  (cart?.discountAmount || 0 - extraPay) +
                                  dbPackages
                                }`}
                              </th>
                            )}
                          </tr>
                          <tr>
                            <th>Totale da pagare oggi</th>

                                <th className="text-end summary-col">
                                  €1
                                </th>

                          </tr>
                        </tbody>
                      </Table>
                    </div>

                    <button
                      onClick={() => handleSubmit()}
                      className="btn btn-primary w-100"
                    >
                      {common?.loading &&
                      common?.loaderName === "UPDATE_CART" ? (
                        <Spinner
                          animation="border"
                          variant="secondary"
                          style={{ height: "18px", width: "18px" }}
                        />
                      ) : (
                        "Procedi"
                      )}
                    </button>
                  </CardBody>
                </Card>
                <Row>
                  <div className="d-flex align-items-center justify-content-center flex-column">
                    <h5 className="pointer">Hai bisogno di aiuto? </h5>
                    <button
                      className="btn btn-default   w-100"
                      onClick={() => {
                        customerly.open();
                      }}
                    >
                      Contattaci{" "}
                    </button>
                  </div>
                </Row>

              </Col>
            </Row>
          </Container>
        </div>
      ) : (
        <div className="page-content card">
          <Container fluid>
            <Row>
              <Col>
                <CardTitle className="mb-3 text-center">
                  Sembra che ti sei perso...
                  <Link to={"https://www.weshoot.it/viaggi-fotografici/"}>
                    Trova il viaggio adatto a te
                  </Link>
                </CardTitle>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </React.Fragment>
  );
};

export default Booking;
