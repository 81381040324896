import {
  SET_BOOKING_DATA_LOCAL,
  SET_TOUR_LOCAL,
  SET_CART_LOCAL,
  SET_VERIFIED_USER,
  SET_BOOKING_USER,
  INIT_CART_LOCAL,
  SET_USER_LOCAL,
} from "./actionTypes";

const INIT_STATE = {
  booking: null,
  cart: null,
  tour: null,
  verified: null,
  user: null,
};

const Booking = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_CART_LOCAL:
      return {
        ...state,
        cart: { ...action.payload },
      };
    case SET_USER_LOCAL:
      return {
        ...state,
        user: {
          ...state.user,
          user: action.payload,
        },
      };
    case SET_VERIFIED_USER:
      return {
        ...state,
        verified: action.payload,
      };
    case SET_BOOKING_USER:
      return {
        ...state,
        user: action.payload,
        verified: null,
      };
    case SET_BOOKING_DATA_LOCAL:
      return {
        ...state,
        booking: action.payload,
      };
    case SET_TOUR_LOCAL:
      return {
        ...state,
        tour: action.payload,
      };
    case SET_CART_LOCAL:
      return {
        ...state,
        cart: { ...action.payload },
      };

    default:
      return state;
  }
};

export default Booking;
