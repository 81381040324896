import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {Link, useParams} from 'react-router-dom'
import {toggleLeftmenu} from '../../store/actions'
import logoDark from '../../assets/images/WeShoot-logo-extended-black.svg'
import {withTranslation} from 'react-i18next'


const Header = (props) => {
  const { id } = useParams();

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <Link to="/login" className="logo logo-dark ps-4">
              <span>
                <img src={logoDark} alt="" height="17" />
              </span>
            </Link>
          </div>

          <div className="d-flex">
            {/*<LanguageDropdown />*/}
            {localStorage.getItem("weshoot-token") && (
                    <div className="nav-item dropdown text-end btn header-item d-flex align-items-center">
                      <Link
                        onClick={() => localStorage.clear()}
                        to={`/booking/${id}`}
                        className="nav-link dropdown-toggle arrow-none"
                      >
                        {props.t("logout")}
                      </Link>
                    </div>
                  )}
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

Header.propTypes = {
  leftMenu: PropTypes.any,
  showRightSidebar: PropTypes.any,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
};

const mapStatetoProps = (state) => {
  const { layoutType, showRightSidebar, leftMenu } = state.Layout;
  return { layoutType, showRightSidebar, leftMenu };
};

export default connect(mapStatetoProps, {
  toggleLeftmenu,
})(withTranslation()(Header));
