import PropTypes from 'prop-types'
import React, {useEffect, useState} from 'react'
import {Collapse, Toast, ToastBody} from 'reactstrap'
import {Link, useParams, withRouter} from 'react-router-dom'
import {withTranslation} from 'react-i18next'
import {clearMessage} from '../../store/common/actions'
import {connect} from 'react-redux'

const Navbar = (props) => {
  const { common } = props;
  const { id } = useParams();
  useEffect(() => {
    var matchingMenuItem = null;
    var ul = document.getElementById("navigation");
    var items = ul.getElementsByTagName("a");
    for (var i = 0; i < items.length; ++i) {
      if (props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  });
  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement;
    if (parent) {
      parent.classList.add("active"); // li
      const parent2 = parent.parentElement;
      parent2.classList.add("active"); // li
      const parent3 = parent2.parentElement;
      if (parent3) {
        parent3.classList.add("active"); // li
        const parent4 = parent3.parentElement;
        if (parent4) {
          parent4.classList.add("active"); // li
          const parent5 = parent4.parentElement;
          if (parent5) {
            parent5.classList.add("active"); // li
            const parent6 = parent5.parentElement;
            if (parent6) {
              parent6.classList.add("active"); // li
            }
          }
        }
      }
    }
    return false;
  }
  const [toast, setToast] = useState(false);
  const [message, setMessage] = useState(null);
  const [severity, setSeverity] = useState(null);

  const toggleToast = () => {
    setToast(!toast);
  };
  useEffect(() => {
    if (common?.showMessage) {
      setToast(true);
      setSeverity(common?.severity);
      setMessage(common?.message);
      setTimeout(() => {
        setToast(false);
        setSeverity(null);
        setMessage(null);
      }, 6000);
      setTimeout(() => {
        props.clearMessage();
      }, 100);
    }
  }, [common?.showMessage]);
  return (
    <>
      <div
        className="position-fixed  top-0 end-0 p-3"
        style={{ zIndex: "1005" }}
      >
        <Toast
          isOpen={toast}
          className={severity === "error" ? "error-toast" : "success-toast"}
        >
          <ToastBody>{message}</ToastBody>
        </Toast>
      </div>
      <React.Fragment>
        <div className="topnav">
          <div className="container-fluid">
            <nav
              className="navbar navbar-light navbar-expand topnav-menu"
              id="navigation"
            >
              <Collapse
                isOpen={props.leftMenu}
                className="navbar-collapse"
                id="topnav-menu-content"
              >
                <ul className="navbar-nav ">
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle arrow-none"
                      to={`/booking/${id}`}
                    >
                      {props.t("Prenotazione")} {props.menuOpen}
                    </Link>
                  </li>

                  <li className="nav-item dropdown">
                    <Link
                      to={`/travellers-details/${id}`}
                      className="nav-link dropdown-toggle arrow-none"
                    >
                      {props.t("Viaggiatori")}
                    </Link>
                  </li>

                  <li className="nav-item dropdown">
                    <Link
                      to={`/account-data/${id}`}
                      className="nav-link dropdown-toggle arrow-none"
                    >
                      {props.t("Account")}
                    </Link>
                  </li>
                  <li className="nav-item dropdown">
                    <Link
                      to={`/payment/${id}`}
                      className="nav-link dropdown-toggle arrow-none"
                    >
                      {props.t("Pagamento")}
                    </Link>
                  </li>
                  
                </ul>
              </Collapse>
            </nav>
          </div>
        </div>
      </React.Fragment>
    </>
  );
};

Navbar.propTypes = {
  leftMenu: PropTypes.any,
  location: PropTypes.any,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
};

const mapStatetoProps = (state) => {
  const { leftMenu } = state.Layout;
  const { common } = state;
  return { leftMenu, common };
};

export default withRouter(
  connect(mapStatetoProps, { clearMessage })(withTranslation()(Navbar))
);
